import React, { useState, useEffect, useReducer, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import jsLogger from "js-logger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserTimes,
  faUserEdit,
  faSyncAlt,
  faArrowCircleLeft,
  faSave,
  faNewspaper
} from "@fortawesome/free-solid-svg-icons";
import ReadOnlyRow from "../../../components/common/_form/_readOnlyRow";
import { API_URL } from "../../../scripts/api";

const AdminUserEdit = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [originalUser, setOriginalUser] = useState(null);
  const [user, setUser] = useReducer((accumulator, currentValue) => {
    if (!currentValue) {
      return currentValue;
    }
    return { ...accumulator, ...currentValue };
  }, null);
  const userDirtyMemo = useMemo(
    () => JSON.stringify(originalUser) !== JSON.stringify(user),
    [originalUser, user]
  );

  const { username } = match.params;
  const usernameMemo = useMemo(() => {
    return decodeURIComponent(username);
  }, [username]);

  useEffect(() => {
    const loadUser = async () => {
      setLoading(true);
      try {
        const url = `${API_URL}/user/${usernameMemo}`;
        jsLogger.debug("Getting user...", { url, usernameMemo });
        const { data } = await axios.get(url, {
          withCredentials: true,
          validateStatus: status => status === 200
        });
        jsLogger.debug("Gotten user!", { data });
        setUser(data);
        setOriginalUser(data);
      } catch (error) {
        setUser(false);
        setOriginalUser(false);
        jsLogger.error(error);
      }
      setLoading(false);
    };
    setUser(null);
    loadUser();
  }, [usernameMemo]);

  const saveUser = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      const url = `${API_URL}/user/${usernameMemo}`;
      jsLogger.debug("Putting user...", { url, usernameMemo });
      const { data } = await axios.put(
        url,
        { user },
        {
          withCredentials: true,
          validateStatus: status => status === 200
        }
      );
      jsLogger.debug("Putted user!", { data });
      history.goBack();
    } catch (error) {
      jsLogger.error(error);
    }
    setLoading(false);
  };

  const EditCheckBox = ({ name, description }) => (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={!!user[name]}
          onChange={({ target }) => setUser({ [name]: target.checked })}
          id={`input-${name}`}
        />
        <label className="ml-2 form-check-label" htmlFor={`input-${name}`}>
          {name}
        </label>
        {description && (
          <div className="ml-2 badge badge-warning">{description}</div>
        )}
      </div>
    </>
  );

  return (
    <div className="text-left">
      <div className="jumbotron jumbotron-fluid bg-primary text-light">
        <div className="container">
          <h1 className="display-4">
            <span className="pr-2">
              <FontAwesomeIcon icon={faUserEdit} size="1x" />
              <span className="pl-2">{usernameMemo}</span>
            </span>
            {loading && (
              <span className="pull-left">
                {<FontAwesomeIcon icon={faSyncAlt} size="1x" spin />}
              </span>
            )}
          </h1>
          <hr className="my-4" />
          <p className="lead">
            <Link to="/admin/user" className="btn btn-light">
              <FontAwesomeIcon icon={faArrowCircleLeft} size="1x" />
            </Link>
            <Link
              to={`/admin/user/${usernameMemo}/article`}
              className="btn btn-link text-light"
            >
              <FontAwesomeIcon icon={faNewspaper} size="1x" />
              <span className="pl-2">Articles</span>
            </Link>
          </p>
        </div>
      </div>
      <div className="container">
        {user && (
          <form>
            <div className="form-group row">
              <ReadOnlyRow
                name="user since"
                value={moment(user.creationDate || null).fromNow()}
              />
              <ReadOnlyRow
                name="email address"
                value={user.emailAddress || "missing"}
              />
              <ReadOnlyRow name="invited by">
                {user.invitedBy ? (
                  <Link
                    to={`/admin/user/${user.invitedBy}`}
                    className="btn btn-link pl-0"
                  >
                    <FontAwesomeIcon icon={faUser} size="1x" />
                    <span className="pl-1">{user.invitedBy}</span>
                  </Link>
                ) : (
                  <span className="mt-2 badge badge-warning">
                    <FontAwesomeIcon icon={faUserTimes} size="1x" />
                    <span className="pl-1">no-one</span>
                  </span>
                )}
              </ReadOnlyRow>
            </div>
            <hr className="my-4" />
            <div className="form-group row">
              <div className="col-12">
                <EditCheckBox
                  name="coventry"
                  description="silently bans user from posting content of all kinds"
                />
              </div>
            </div>
            <hr className="my-4" />
            <div className="form-group row">
              <div className="col-12">
                <EditCheckBox name="moderator" />
                <EditCheckBox name="admin" />
              </div>
            </div>
            <hr className="my-4" />
            <div className="form-group row">
              <div className="col-12">
                <button
                  className="btn btn-warning"
                  disabled={!userDirtyMemo || loading}
                  onClick={event => saveUser(event)}
                >
                  {loading ? (
                    <FontAwesomeIcon icon={faSyncAlt} size="1x" spin />
                  ) : (
                    <FontAwesomeIcon icon={faSave} size="1x" />
                  )}
                  <span className="pl-1">Save</span>
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AdminUserEdit;
