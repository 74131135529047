import axios from "axios";
import jsLogger from "js-logger";
import { API_URL } from "../../../api";
import md5 from "js-md5";

const SET_UPDATING_USER_AUTH = "SET_UPDATING_USER_AUTH";
const SUCCESS_UPDATING_USER_AUTH = "SUCCESS_UPDATING_USER_AUTH";

const putUserAuth = ({ username, auth }) => {
  return async dispatch => {
    try {
      dispatch({ type: SET_UPDATING_USER_AUTH, loading: true });
      const url = `${API_URL}/user/${username}/auth`;
      jsLogger.debug("Putting user auth to API...", { url });
      const { data } = await axios.put(
        url,
        {
          username,
          auth: {
            currentPassword: md5(auth.currentPassword),
            newPassword: md5(auth.newPassword)
          }
        },
        {
          validateStatus: status => status === 200
        }
      );
      jsLogger.debug("Put user auth!", { data });
      dispatch({
        type: SUCCESS_UPDATING_USER_AUTH,
        loading: false,
        success: data
      });
      window.location.reload();
    } catch (error) {
      window.alert("Failed to update password!");
      dispatch({
        type: SET_UPDATING_USER_AUTH,
        loading: false,
        success: false
      });
      jsLogger.error(error.callStack || error);
    }
  };
};

export { SET_UPDATING_USER_AUTH, putUserAuth };
