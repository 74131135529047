const { REACT_APP_API_URL, REACT_APP_CDN_URL: CDN_URL } = process.env;

const getApiUrl = () => {
  let environment = "production";
  const { hostname } = window.location;
  const [subdomain] = hostname.split("-");
  if (subdomain === "test" || hostname.startsWith("localhost")) {
    environment = "test";
  }
  return `${REACT_APP_API_URL}/${environment}`;
};
const API_URL = getApiUrl();

export { CDN_URL, API_URL };
