import React, { useState, useReducer } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import md5 from "js-md5";
import jsLogger from "js-logger";
import {
  login,
  logout
} from "../../scripts/redux/actions/authenticationActions";
import { API_URL } from "../../scripts/api";

const Login = ({ className, history }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [state, reduceState] = useReducer(
    (accumulator, { key, value }) => ({
      ...accumulator,
      [key]: value
    }),
    { username: "", password: "" }
  );
  const handleChange = ({ target }) => {
    jsLogger.trace("Updating state...", { target });
    reduceState({ key: target.name, value: target.value });
    jsLogger.trace("Updated state!", { state });
  };

  const postLogin = async event => {
    jsLogger.info("Posting login...", { event, state });
    event.preventDefault();
    if (loading) {
      jsLogger.warn("Cannot login: already logging in!", { loading });
      return;
    }

    setLoading(true);

    try {
      const url = `${API_URL}/login`;
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      const { username, password } = state;
      const body = `username=${username}&password=${md5(password)}`;
      jsLogger.debug("Posting login...", { url, body, headers });
      const { data, status } = await axios.post(url, body, {
        validateStatus: status => status === 204,
        withCredentials: true,
        headers
      });
      jsLogger.debug("Posted login!", { data, status });
      dispatch(login({ user: data }));
      history.push("/");
    } catch ({ response }) {
      dispatch(logout());
      let text = "Failed to login!";
      if (response && response.statusText) {
        text = response.statusText;
      }
      window.alert(text);
      setLoading(false);
    }
  };

  return (
    <div className={`login card border-primary text-left ${className}`}>
      <div className="card-header text-white bg-primary">
        <h5>Sign-in</h5>
      </div>
      <fieldset disabled={loading}>
        <form id="login__form" onSubmit={postLogin}>
          <div className="card-body bg-light text-dark">
            <div className="form-group">
              <label>Username</label>
              <input
                type="text"
                className="form-control"
                name="username"
                value={state.username}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={state.password}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary">
                {loading ? (
                  <FontAwesomeIcon icon={faCircleNotch} size="1x" spin />
                ) : (
                  <FontAwesomeIcon icon={faSignInAlt} size="1x" />
                )}
                <span className="pl-1">Login</span>
              </button>
            </div>
          </div>
        </form>
      </fieldset>
    </div>
  );
};

export default withRouter(Login);
