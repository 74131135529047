import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Col, Form, InputGroup } from "react-bootstrap";
import UploadImageModal from "../../../common/_uploadImageModal";

const ArticleInfoEditor = ({
  className,
  article,
  setArticle,
  cacheUnsavedArticle,
  history
}) => {
  const [showThumbnailUploader, setShowThumbnailUploader] = useState(false);

  const maxTitleLength = 60 - " | Open Columnist".length;
  const maxExcerptLength = 150;

  const validateTitle = article.title.length <= maxTitleLength;
  const validateExcerpt = article.blurb.length <= maxExcerptLength;

  const ValidationText = ({ validate, children }) => {
    const className = validate
      ? "bg-success text-light"
      : "bg-danger text-light";
    return <InputGroup.Text className={className}>{children}</InputGroup.Text>;
  };

  return (
    <Form className={`text-left pt-3 ${className}`}>
      <Form.Row>
        <Form.Group as={Col} xs={12}>
          <Form.Label className="mb-0">Title</Form.Label>
          <Form.Text id="articleTitleHelp" className="pb-2" muted>
            This will appear on at the top of the article and in content lists.
          </Form.Text>
          <InputGroup>
            <Form.Control
              type="text"
              className="form-control form-control-lg"
              id="articleTitle"
              aria-describedby="articleTitleHelp"
              placeholder="Title"
              value={article.title}
              onChange={({ target }) => setArticle({ title: target.value })}
            />
            <InputGroup.Append>
              <ValidationText validate={validateTitle}>
                {article.title.length} / {maxTitleLength}
              </ValidationText>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        {showThumbnailUploader && (
          <UploadImageModal
            articleId={article.articleId}
            show={showThumbnailUploader}
            onHide={(image = {}) => {
              if (image && image.url) {
                const images = [...(article.images || []), image].filter(
                  obj => obj.url !== article.thumbnail
                );
                setArticle({ thumbnail: image.url, images });
              }
              setShowThumbnailUploader(false);
            }}
          />
        )}
        {article.thumbnail && (
          <Col xs={12} md={4} className="mb-3">
            <img
              className="w-100 h-100"
              src={article.thumbnail}
              alt={article.thumbnail}
            />
          </Col>
        )}
        <Col xs={12} md className="mb-3">
          <Form.Label className="mb-0">Thumbnail</Form.Label>
          <Form.Text id="articleThumbnailHelp" className="pb-2" muted>
            This will appear alongside your article title and excerpt wherever
            it's listed.
          </Form.Text>
          <InputGroup>
            <input
              className="form-control"
              value={article.thumbnail || "Thumbnail"}
              aria-describedby="articleThumbnailHelp"
              readOnly
            />
            <InputGroup.Append>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => setShowThumbnailUploader(true)}
              >
                <FontAwesomeIcon icon={faUpload} size="1x" />
                <span className="pl-1">Upload</span>
              </button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={12}>
          <Form.Label className="mb-0">Excerpt</Form.Label>
          <Form.Text id="articleBlurbHelp" className="pb-2" muted>
            This will appear on at the top of the article and in content lists.
          </Form.Text>
          <InputGroup>
            <Form.Control
              id="articleBlurb"
              as="textarea"
              aria-describedby="articleBlurbHelp"
              placeholder="Excerpt"
              rows="2"
              value={article.blurb}
              onChange={({ target }) => setArticle({ blurb: target.value })}
            />
            <InputGroup.Append>
              <ValidationText validate={validateExcerpt}>
                {article.blurb.length} / {maxExcerptLength}
              </ValidationText>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};

export default withRouter(ArticleInfoEditor);
