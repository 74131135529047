import { LOGIN, LOGOUT } from "../actions/authenticationActions.js";

const initialState = {
  loggedIn: false,
  user: null
};

const authenticationReducer = (state = initialState, { type, user } = {}) => {
  switch (type) {
    case LOGIN:
      return Object.assign({}, state, {
        loggedIn: true,
        user
      });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default authenticationReducer;
