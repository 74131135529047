import axios from "axios";
import jsLogger from "js-logger";
import { API_URL } from "../../api";

const SET_LOADING_DRAFTARTICLES = "SET_LOADING_DRAFTARTICLES";
const SUCCESS_LOADING_DRAFTARTICLES = "SUCCESS_LOADING_DRAFTARTICLES";
const ADD_DRAFTARTICLE = "ADD_DRAFTARTICLE";
const DELETE_DRAFTARTICLE = "DELETE_DRAFTARTICLE";
const CACHE_DRAFTARTICLE = "CACHE_DRAFTARTICLE";
const UPDATE_DRAFTARTICLE = "UPDATE_DRAFTARTICLE";

const loadArticles = ({ username }, callback) => {
  return async dispatch => {
    if (!username) {
      jsLogger.warn("Username should not be falsey!");
      return;
    }
    try {
      dispatch({ type: SET_LOADING_DRAFTARTICLES, loading: true, username });

      const url = `${API_URL}/user/${username}/draft/article`;
      jsLogger.debug("Getting articles...", { url });
      const { data } = await axios.get(url);
      jsLogger.debug("Gotten articles!", { data });

      dispatch({
        type: SUCCESS_LOADING_DRAFTARTICLES,
        value: { draftArticles: data.articles },
        loading: false,
        key: username,
        timestamp: new Date()
      });
      if (callback) {
        callback(data, false);
      }
    } catch (error) {
      dispatch({
        type: SET_LOADING_DRAFTARTICLES,
        loading: false,
        key: username
      });
      jsLogger.error(error.callStack || error);
      if (callback) {
        callback(false, error);
      }
    }
  };
};

const publishArticle = ({ username, article }, callback) => {
  return async dispatch => {
    if (!username) {
      jsLogger.warn("Username should not be falsey!");
      return;
    }
    try {
      dispatch({
        type: SET_LOADING_DRAFTARTICLES,
        loading: true,
        key: username
      });
      const url = `${API_URL}/user/${username}/draft/article/${article.articleId}/publish`;
      jsLogger.debug("Publishing draft article...", { url });
      await axios.post(url, false, {
        validateStatus: status => status === 201
      });
      jsLogger.debug("Published draft article!");
      dispatch({ type: DELETE_DRAFTARTICLE, value: article, key: username });
      if (callback) {
        callback(null, false);
      }
    } catch (error) {
      dispatch({
        type: SET_LOADING_DRAFTARTICLES,
        loading: false,
        key: username
      });
      jsLogger.error(error.callStack || error);
      if (callback) {
        callback(false, error);
      }
    }
  };
};

const putArticle = ({ username, article, articleId }, callback) => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_LOADING_DRAFTARTICLES,
        loading: true,
        key: username
      });

      const url = `${API_URL}/user/${username}/draft/article/${articleId}`;
      jsLogger.debug("Putting draft article...", { url, article });
      const { data } = await axios.put(url, article, {
        validateStatus: status => {
          if (status === 201) {
            jsLogger.warn(
              "New article was created! Should have updated existing article!",
              {
                articleId,
                status,
                data
              }
            );
          }
          return status === 200;
        }
      });
      jsLogger.debug("Putted draft article!", { data });

      dispatch({ type: UPDATE_DRAFTARTICLE, value: data, key: username });
      if (callback) {
        callback(data, false);
      }
    } catch (error) {
      dispatch({
        type: SET_LOADING_DRAFTARTICLES,
        loading: false,
        key: username
      });
      jsLogger.error(error.callStack || error);
      if (callback) {
        callback(false, error);
      }
    }
  };
};

const postArticle = ({ username }, article, callback) => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_LOADING_DRAFTARTICLES,
        loading: true,
        key: username
      });

      const url = `${API_URL}/user/${username}/draft/article`;
      jsLogger.debug("Posting draft article...", { url, article });
      const { data } = await axios.post(url, article, {
        validateStatus: status => {
          if (status === 200) {
            jsLogger.warn(
              "New article was not created! Should have created new article!",
              {
                status,
                data
              }
            );
          }
          return status === 201;
        }
      });

      dispatch({
        type: ADD_DRAFTARTICLE,
        value: data,
        key: username,
        loading: false
      });

      jsLogger.debug("Posted draft article!", { data });
      if (callback) {
        callback(data, false);
      }
    } catch (error) {
      dispatch({
        type: SET_LOADING_DRAFTARTICLES,
        loading: false,
        key: username
      });
      jsLogger.error(error.callStack || error);
      if (callback) {
        callback(false, error);
      }
    }
  };
};

const deleteArticle = (username, article, callback) => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_LOADING_DRAFTARTICLES,
        loading: true,
        key: username
      });

      const url = `${API_URL}/user/${username}/draft/article/${article.articleId}`;
      jsLogger.debug("Deleting article remotely...", { url });
      const { data } = await axios.delete(url);
      jsLogger.debug("Deleted article!", { data });

      jsLogger.debug("Deleting article locally...");
      dispatch({
        type: DELETE_DRAFTARTICLE,
        value: article,
        loading: false,
        key: username
      });

      if (callback) {
        callback(data, false);
      }
    } catch (error) {
      dispatch({
        type: SET_LOADING_DRAFTARTICLES,
        loading: false,
        key: username
      });
      jsLogger.error(error.callStack || error);
      if (callback) {
        callback(false, error);
      }
    }
  };
};

const cacheArticle = ({ username }, article, callback) => {
  return async dispatch => {
    jsLogger.debug("Caching article...", { article });
    dispatch({
      type: CACHE_DRAFTARTICLE,
      value: article,
      key: username
    });

    if (callback) {
      callback(null, false);
    }
  };
};

export {
  loadArticles,
  publishArticle,
  ADD_DRAFTARTICLE,
  putArticle,
  postArticle,
  deleteArticle,
  cacheArticle
};
