import React from "react";
import Login from "../components/common/_login";

const LoginWrapper = () => {
  return (
    <div
      className="login-wrapper container d-flex justify-content-center"
      style={{ height: "100vh", alignItems: "center" }}
    >
      <div className="flex-row">
        <Login className="" />
      </div>
    </div>
  );
};

export default LoginWrapper;
