import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { Row, Container, Tabs, Tab } from "react-bootstrap";
import UserInvitations from "../components/profile/invitations";
import UserApiKeys from "../components/profile/apiKeys";
import ReadOnlyRow from "../components/common/_form/_readOnlyRow";
import PasswordForm from "../components/profile/passwordForm";

const Home = () => {
  const { user, loading } = useSelector(state => state.authenticationReducer);
  const { username } = user;

  return (
    <div className="text-left">
      <div className="jumbotron jumbotron-fluid bg-primary text-light">
        <Container>
          <h1 className="display-4">
            <span className="pr-2">
              <FontAwesomeIcon icon={faUser} size="1x" />
              <span className="pl-2">Profile</span>
            </span>
            {loading && (
              <span className="pull-left">
                {<FontAwesomeIcon icon={faSyncAlt} size="1x" spin />}
              </span>
            )}
          </h1>
          <hr className="my-4" />
          <p className="lead">Hey, {username}!</p>
        </Container>
      </div>
      <Container>
        <Tabs defaultActiveKey="userInfo" variant="pills">
          <Tab eventKey="userInfo" title="User Info">
            <Row className="form-group pt-2">
              <ReadOnlyRow
                name="user since"
                value={moment(user.creationDate || null).fromNow()}
              />
              <ReadOnlyRow
                name="email address"
                value={user.emailAddress || "missing"}
              />
            </Row>
            <hr className="my-4 bg-light" />
            <PasswordForm username={username} />
          </Tab>
          {user.admin && (
            <Tab eventKey="apiKeys" title="API Keys">
              <UserApiKeys className="pt-2" />
            </Tab>
          )}
          {user.admin && (
            <Tab eventKey="invitationCodes" title="Invitation Codes">
              <UserInvitations className="pt-2" />
            </Tab>
          )}
        </Tabs>
      </Container>
    </div>
  );
};

export default Home;
