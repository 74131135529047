import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import axios from "axios";
import jsLogger from "js-logger";
import { LOGOUT } from "./scripts/redux/actions/authenticationActions";
import configureStore from "./scripts/redux/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import Authentication from "./components/authentication";
import Login from "./routes/login";
import Logout from "./routes/logout";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./routes/index";
import Profile from "./routes/profile";
import ArticleDraftList from "./routes/article/draft/list";
import ArticlePublishedList from "./routes/article/published/list";
import EditArticleEditor from "./routes/article/draft/editor/editArticleEditor";
import RedeemInvitation from "./routes/redeemInvitation";
import ModeratorReviewList from "./routes/moderator/review/list";
import AdminUserList from "./routes/admin/user/list";
import AdminEditUser from "./routes/admin/user/edit";
import AdminUserArticleList from "./routes/admin/user/article/list";
import AdminInquestList from "./routes/admin/inquest/list";
import WithAuth from "./components/_library/_auth/withAuth";
import WithModerator from "./components/_library/_auth/withModerator";
import WithAdmin from "./components/_library/_auth/withAdmin";
import UserArticleView from "./routes/user/article/view";

const { store, persistor } = configureStore();

axios.interceptors.request.use(
  config => {
    return {
      withCredentials: true,
      ...config
    };
  },
  error => {
    jsLogger.error(error);
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(null, error => {
  if (error.response && error.response.status === 401) {
    const { request } = error;
    if (!request.responseURL.endsWith("/auth")) {
      jsLogger.warn("Unauthorised!", { request });
      store.dispatch({ type: LOGOUT });
    }
  }
  jsLogger.error(error);
  return Promise.reject(error);
});

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route
                exact
                path="/redeem-invitation/:invitationCode"
                component={RedeemInvitation}
              />
              <Authentication>
                <WithAuth>
                  <Header />
                  <Route exact path="/" component={Home} />
                  <Route exact path="/profile" component={Profile} />
                  <Route
                    exact
                    path="/article/draft"
                    component={ArticleDraftList}
                  />
                  <Route
                    exact
                    path="/article/published"
                    component={ArticlePublishedList}
                  />
                  <Route
                    exact
                    path="/article/:articleId/edit"
                    component={EditArticleEditor}
                  />
                  <Route
                    exact
                    path="/user/:username/article/:articleId/view"
                    component={UserArticleView}
                  />
                </WithAuth>
                <WithModerator>
                  <Route
                    exact
                    path="/moderator/review"
                    component={ModeratorReviewList}
                  />
                </WithModerator>
                <WithAdmin>
                  <Route exact path="/admin/user" component={AdminUserList} />
                  <Route
                    exact
                    path="/admin/user/:username"
                    component={AdminEditUser}
                  />
                  <Route
                    exact
                    path="/admin/user/:username/article"
                    component={AdminUserArticleList}
                  />
                  <Route
                    exact
                    path="/admin/inquest"
                    component={AdminInquestList}
                  />
                </WithAdmin>
              </Authentication>
            </Switch>
            <Footer />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
