import axios from "axios";
import jsLogger from "js-logger";
import { API_URL } from "../../../api";

const SET_USER_APIKEYS = "SET_USER_APIKEYS";
const SET_USER_APIKEYS_LOADING = "SET_USER_APIKEYS_LOADING";
const ADD_USER_APIKEY = "ADD_USER_APIKEY";
const CREATE_USER_APIKEY = "CREATE_USER_APIKEY";
const DELETE_USER_APIKEY = "DELETE_USER_APIKEY";

const loadApiKeys = ({ username }) => {
  return async dispatch => {
    try {
      dispatch({ type: SET_USER_APIKEYS_LOADING, loading: true });
      const url = `${API_URL}/user/${username}/apikey`;
      jsLogger.debug("Getting user api keys...", { url });
      const { data } = await axios.get(url, {
        withCredentials: true,
        validateStatus: status => status === 200
      });
      jsLogger.debug("Gotten user api keys!", { data });
      dispatch({ type: SET_USER_APIKEYS, data: data.keys });
    } catch (error) {
      dispatch({ type: SET_USER_APIKEYS, data: [] });
      jsLogger.error(error.callStack || error);
    }
  };
};

const createApiKey = ({ username }) => {
  return async dispatch => {
    try {
      dispatch({ type: SET_USER_APIKEYS_LOADING, loading: true });
      const url = `${API_URL}/user/${username}/apikey`;
      jsLogger.debug("Posting user api key...", { url });
      const { data } = await axios.post(url, {
        withCredentials: true,
        validateStatus: status => status === 201
      });
      jsLogger.debug("Created user api key!", { data });
      dispatch({ type: ADD_USER_APIKEY, data: data.apiKey });
    } catch (error) {
      dispatch({ type: SET_USER_APIKEYS_LOADING, loading: false });
      jsLogger.error(error.callStack || error);
    }
  };
};

const deleteApiKey = ({ username }, apiKey) => {
  return async dispatch => {
    try {
      dispatch({ type: SET_USER_APIKEYS_LOADING, loading: true });
      const url = `${API_URL}/user/${username}/apikey/${apiKey.key}`;
      jsLogger.debug("Deleting user api key...", { url });
      await axios.delete(url, {
        withCredentials: true,
        validateStatus: status => status === 200
      });
      jsLogger.debug("Deleted user api key!", { apiKey });
      dispatch({ type: DELETE_USER_APIKEY, data: apiKey });
    } catch (error) {
      dispatch({ type: SET_USER_APIKEYS_LOADING, loading: false });
      jsLogger.error(error.callStack || error);
    }
  };
};

export {
  SET_USER_APIKEYS,
  loadApiKeys,
  createApiKey,
  deleteApiKey,
  SET_USER_APIKEYS_LOADING,
  ADD_USER_APIKEY,
  CREATE_USER_APIKEY,
  DELETE_USER_APIKEY
};
