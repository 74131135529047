import React, { useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import jsLogger from "js-logger";
import { LOGIN, LOGOUT } from "../scripts/redux/actions/authenticationActions";
import { API_URL } from "../scripts/api";

const Authentication = ({ children, history }) => {
  const dispatch = useDispatch();

  const checkAuthenticated = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_URL}/whoami`);
      dispatch({ type: LOGIN, user: data });
    } catch (error) {
      jsLogger.warn("User logged out! Redirecting to login...", { error });
      dispatch({ type: LOGOUT });
      history.push("/login");
    }
  }, [history, dispatch]);

  useEffect(() => {
    checkAuthenticated();
  }, [checkAuthenticated]);

  const { loggedIn } = useSelector(state => state.authenticationReducer);

  if (loggedIn) {
    return <div>{children}</div>;
  }
  return <div />;
};

export default withRouter(Authentication);
