import ReactGA from "react-ga";

const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";

const login = ({ user }) => {
  return dispatch => {
    ReactGA.set({ userId: user.username });
    dispatch({ type: LOGIN, user });
  };
};

const logout = () => {
  return dispatch => {
    dispatch({ type: LOGOUT });
  };
};

export { LOGIN, login, LOGOUT, logout };
