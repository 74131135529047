import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect
} from "react";
import {
  EditorState,
  ContentState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  convertFromHTML
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import createResizeablePlugin from "draft-js-resizeable-plugin";
import createImagePlugin from "draft-js-image-plugin";
import { stateToHTML } from "draft-js-export-html";
import { stateToMarkdown } from "draft-js-export-markdown";
import { stateFromMarkdown } from "draft-js-import-markdown";
import jsLogger from "js-logger";
import RichTextEditorControls from "./_draftjs/_controls";
import linkPlugin from "./_draftjs/_linkControl";
import deepEqual from "deep-equal";

const resizeablePlugin = createResizeablePlugin();

const RichTextEditor = (
  { articleId, content, disabled, setIsContentModified, article, setArticle },
  ref
) => {
  const editorTypes = [
    { key: "rich", value: "rich text" },
    { key: "html", value: "html" },
    { key: "markdown", value: "markdown", disabled: true }
  ];

  const [editorType, setEditorType] = useState("rich");
  const [spellCheck, setSpellCheck] = useState(true);
  const [contentHtml, setContentHtml] = useState(content || "");
  const [contentMarkdown, setContentMarkdown] = useState("");
  const [originalContent] = useState(content || "");

  jsLogger.debug("Creating new editor...", { content });
  const [editorState, setEditorState] = useState(
    content
      ? EditorState.createWithContent(convertFromRaw(content))
      : EditorState.createEmpty()
  );
  useImperativeHandle(
    ref,
    () => ({
      getContent: () => {
        const currentContent = editorState.getCurrentContent();
        const content = {
          content: convertToRaw(currentContent),
          html: stateToHTML(currentContent),
          markdown: stateToMarkdown(currentContent)
        };
        jsLogger.debug("Generated raw content from editor!", {
          content
        });
        return content;
      }
    }),
    [editorState]
  );

  useEffect(() => {
    const currentContent = editorState.getCurrentContent();
    setIsContentModified(
      !deepEqual(originalContent, convertToRaw(currentContent))
    );
  }, [editorState, originalContent, setIsContentModified]);

  const handleKeyCommand = (command, editorState) => {
    const newEditorState = RichUtils.handleKeyCommand(editorState, command);
    if (newEditorState) {
      setEditorState(newEditorState);
      return "handled";
    }
    return "not-handled";
  };

  const changeEditorStyle = value => {
    if (value === editorType) {
      return;
    }

    jsLogger.debug("Updating editor state...", { value });
    setEditorType(value);

    const currentContent = editorState.getCurrentContent();
    switch (value) {
      case "rich":
        break;
      case "html":
        jsLogger.debug("Convering to html...", { currentContent });
        setContentHtml(stateToHTML(currentContent));
        break;
      case "markdown":
        jsLogger.debug("Convering to markdown...", { currentContent });
        setContentMarkdown(stateToMarkdown(currentContent));
        break;
      default:
        jsLogger.error(
          "Cannot convert: type is unsupported! Defaulting to 'html'...",
          { value }
        );
        changeEditorStyle("html");
        break;
    }
  };

  const updateContent = (editorType, value) => {
    switch (editorType) {
      case "html":
        setContentHtml(value);
        jsLogger.debug("Converting Html to DraftJS raw state...", { value });
        const blocksFromHtml = convertFromHTML(value);
        const state = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap
        );
        setEditorState(EditorState.createWithContent(state));
        break;
      case "markdown":
        setContentMarkdown(value);
        jsLogger.debug("Converting Html to DraftJS raw state...", { value });
        setEditorState(EditorState.createWithContent(stateFromMarkdown(value)));
        break;
      default:
        jsLogger.error("Cannot update content: type is unsupported!", {
          value
        });
        break;
    }
  };

  const styleMap = {
    CODE: {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2
    }
  };

  const imagePlugin = createImagePlugin({
    decorator: resizeablePlugin.decorator
  });

  return (
    <div className="rich-text-editor">
      <div className="rich-text-editor__editor-type btn-group">
        {editorTypes
          .filter(({ disabled }) => !disabled)
          .map(({ key, value }) => (
            <button
              key={key}
              className={`btn btn-sm btn-${
                key === editorType ? "success" : "primary"
              }`}
              onClick={() => changeEditorStyle(key)}
            >
              {value}
            </button>
          ))}
      </div>
      <fieldset disabled={disabled}>
        {editorType === "rich" && (
          <>
            <RichTextEditorControls
              articleId={articleId}
              editorState={editorState}
              customStyleMap={styleMap}
              setEditorState={setEditorState}
              spellCheck={spellCheck}
              setSpellCheck={setSpellCheck}
              article={article}
              setArticle={setArticle}
            />
            <div className="rich-text-editor__text-box">
              <Editor
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                onChange={setEditorState}
                plugins={[resizeablePlugin, imagePlugin, linkPlugin]}
                spellCheck={spellCheck}
                readOnly={disabled}
              />
            </div>
          </>
        )}
        {editorType !== "rich" && (
          <>
            <textarea
              className="rich-text-editor__text-box"
              value={
                editorType === "html"
                  ? contentHtml
                  : editorType === "markdown"
                  ? contentMarkdown
                  : ""
              }
              onChange={({ target }) => updateContent(editorType, target.value)}
            />
          </>
        )}
      </fieldset>
    </div>
  );
};

export default forwardRef(RichTextEditor);
