import axios from 'axios';
import jsLogger from 'js-logger';
import { API_URL } from '../../../api';

const SET_MODERATOR_REVIEW_LOADING = 'SET_MODERATOR_REVIEW_LOADING';
const SET_MODERATOR_REVIEWS = 'SET_MODERATOR_REVIEWS';

const getModeratorReviews = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_MODERATOR_REVIEW_LOADING, loading: true });
      const url = `${API_URL}/moderator/review?limit=1000`;
      jsLogger.debug('Getting moderator reviews...', { url });
      const { data } = await axios.get(url, {
        withCredentials: true,
        validateStatus: (status) => status === 200,
      });
      jsLogger.debug('Gotten users!', { data });
      dispatch({ type: SET_MODERATOR_REVIEWS, data: data.reviews });
    } catch (error) {
      dispatch({ type: SET_MODERATOR_REVIEWS, data: [] });
      dispatch({ type: SET_MODERATOR_REVIEW_LOADING, loading: false });
      jsLogger.error(error.callStack || error);
    }
  };
};

export {
  SET_MODERATOR_REVIEW_LOADING,
  SET_MODERATOR_REVIEWS,
  getModeratorReviews,
};
