import axios from "axios";
import jsLogger from "js-logger";
import { API_URL } from "../../api";
import { ADD_DRAFTARTICLE } from "./draftArticleActions.js";

const SET_LOADING_PUBLISHEDARTICLES = "SET_LOADING_PUBLISHEDARTICLES";
const SUCCESS_LOADING_PUBLISHEDARTICLES = "SUCCESS_LOADING_PUBLISHEDARTICLES";
const DELETE_PUBLISHEDARTICLE = "DELETE_PUBLISHEDARTICLE";

const loadArticles = ({ username }, callback) => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_LOADING_PUBLISHEDARTICLES,
        loading: true,
        username
      });
      const url = `${API_URL}/user/${username}/published/article`;
      jsLogger.debug("Getting user article list...", { url });
      const { data } = await axios.get(url, {
        validateStatus: status => status === 200
      });
      jsLogger.debug("Gotten articles!", { data });
      dispatch({
        type: SUCCESS_LOADING_PUBLISHEDARTICLES,
        value: { publishedArticles: data.articles },
        username,
        loading: false
      });
      if (callback) {
        callback(data, false);
      }
    } catch (error) {
      if (error.statusCode === 404) {
        const data = [];
        dispatch({
          type: SUCCESS_LOADING_PUBLISHEDARTICLES,
          value: { publishedArticles: data },
          username,
          loading: false
        });
      } else {
        dispatch({
          type: SET_LOADING_PUBLISHEDARTICLES,
          loading: false,
          username
        });
        jsLogger.error(error.callStack || error);
      }
      if (callback) {
        callback(false, error);
      }
    }
  };
};

const unpublishArticle = ({ username, article }, callback) => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_LOADING_PUBLISHEDARTICLES,
        loading: true,
        username
      });
      const url = `${API_URL}/user/${username}/published/article/${article.publishedArticleId}/unpublish`;
      jsLogger.debug("Unpublishing published article...", { url });
      const { data } = await axios.post(url, false, {
        validateStatus: status => status === 201
      });
      jsLogger.debug("Unpublished published article!", { data });
      dispatch({
        type: DELETE_PUBLISHEDARTICLE,
        value: article,
        username,
        loading: false
      });
      dispatch({
        type: ADD_DRAFTARTICLE,
        value: data,
        key: username,
        loading: false
      });
      if (callback) {
        callback(data, false);
      }
    } catch (error) {
      dispatch({
        type: SET_LOADING_PUBLISHEDARTICLES,
        loading: false,
        username
      });
      jsLogger.error(error.callStack || error);
      if (callback) {
        callback(false, error);
      }
    }
  };
};

export { loadArticles, unpublishArticle };
