import React, { useEffect } from "react";
import jsLogger from "js-logger";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCopy, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { loadApiKeys, createApiKey, deleteApiKey } from "../../scripts/redux/actions/user/apiKeyActions";
import { Row, Col } from "react-bootstrap";
import Table from "../common/_table";

const UserApiKeys = ({ className }) => {
  const { user } = useSelector(state => state.authenticationReducer);
  const { apiKeys, loading } = useSelector(state => state.userApiKeyReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadApiKeys(user));
  }, [user, dispatch]);

  const copyInviationCodeToClipboard = invitationCode => {
    const copyText = document.getElementById(
      `api-key__${invitationCode}`
    );
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    jsLogger.debug("Copied invitation code to clipboard!", {
      invitationCode,
      copyText
    });
  };

  const ApiKeyRow = ({ data }) => (
    <td className="align-middle">
      <div className="input-group">
        <input
          id={`api-key__${data.key}`}
          className="form-control-plaintext text-white pr-2"
          type="text"
          value={data.key}
          readOnly
        />
        <div className="input-group-append">
          <button
            style={{ borderRadius: "0.25em 0 0 0.25em" }}
            className="input-group-text btn btn-primary"
            onClick={() => copyInviationCodeToClipboard(data.key)}
          >
            <FontAwesomeIcon icon={faCopy} size="1x" />
          </button>
          <button
            className="input-group-text btn btn-danger"
            onClick={() => dispatch(deleteApiKey(user, data))}
          >
            <FontAwesomeIcon icon={faTrashAlt} size="1x" />
          </button>
        </div>
      </div>
    </td>
  );

  return (
    <Row className={className}>
      <Col lg>
        <p className="lead">
          Here are all the <b>API Keys</b> you've already provisioned.
        </p>
        <button
          className="btn btn-light"
          onClick={() => dispatch(createApiKey(user))}
          disabled={loading}
        >
          <FontAwesomeIcon icon={faPlus} size="1x" />
          <span className="pl-2">Provision new key</span>
        </button>
        <hr className="my-4" />
      </Col>
      <Col lg>
        <Table
          row={ApiKeyRow}
          data={apiKeys}
          loading={loading}
          noDataText="No API keys!"
        />
      </Col>
    </Row>
  );
};

export default UserApiKeys;
