import React from "react";
import { useSelector } from "react-redux";
import WithAuth from "./withAuth";
import Unauthorised from "./_unauthorised";

export default ({ children, showWarning = false }) => {
  const { user } = useSelector(state => state.authenticationReducer);
  if (!user || !user.admin) {
    if (showWarning) {
      return <Unauthorised>Unauthorised - must be admin!</Unauthorised>;
    }
    return <div />;
  }
  return <WithAuth showWarning={showWarning}>{children}</WithAuth>;
};
