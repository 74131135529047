import {
  SET_ADMIN_USER_LOADING,
  SET_ADMIN_USERS
} from "../../actions/admin/adminUserActions.js";

const initialState = {
  users: null,
  loading: false
};

const adminUserReducer = (
  state = initialState,
  { type, data, loading } = {}
) => {
  switch (type) {
    case SET_ADMIN_USER_LOADING:
      return Object.assign({}, state, {
        loading
      });
    case SET_ADMIN_USERS:
      return Object.assign({}, state, {
        users: data,
        loading: false
      });
    default:
      return state;
  }
};

export default adminUserReducer;
