import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { postArticle } from "../../../scripts/redux/actions/draftArticleActions";
import ArticleDraftListComponent from "../../../components/article/draft/list";

const ArticleDraftList = ({ history }) => {
  const { user } = useSelector(state => state.authenticationReducer);
  const { [user.username]: state = {} } = useSelector(
    state => state.draftArticleReducer
  );
  const { loading } = state;

  const dispatch = useDispatch();
  const createNewArticle = async () => {
    if (!user || !user.username) {
      return;
    }

    const newArticle = { title: "", blurb: "", html: "" };
    dispatch(
      postArticle(user, newArticle, (article, error) => {
        if (!error) {
          history.push(`/article/${article.articleId}/edit`);
        }
      })
    );
  };

  return (
    <div className="text-left">
      <div className="jumbotron jumbotron-fluid bg-primary text-light">
        <div className="container">
          <h1 className="display-4">
            <span>Draft Articles</span>
            <span className="pull-left">
              &nbsp;
              {loading && <FontAwesomeIcon icon={faSyncAlt} size="1x" spin />}
            </span>
          </h1>
          <p className="lead">Welcome back! These are your draft articles.</p>
          <hr className="my-4" />
          <p className="lead">
            <button
              className="btn btn-light"
              type="button"
              onClick={() => createNewArticle()}
            >
              <FontAwesomeIcon icon={faPlus} size="1x" />
              <span className="pl-2">Create new article</span>
            </button>
          </p>
        </div>
      </div>
      <div className="container">
        <ArticleDraftListComponent className="row" user={user} />
      </div>
    </div>
  );
};

export default withRouter(ArticleDraftList);
