import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import {
  loadArticles,
  deleteArticle
} from "../../../../scripts/redux/actions/draftArticleActions";
import ArticleLastModified from "../_lastModified";
import Table from "../../_table";
import PublishArticle from "./_publish";

const ArticleDraftList = ({ history, className, user, isAdmin }) => {
  const { username } = user;

  const { [username]: state = {} } = useSelector(
    state => state.draftArticleReducer
  );
  const { loading, timestamp, draftArticles } = state;

  const dispatch = useDispatch();

  const hasUnsavedChanges = article => {
    if (!article.originalArticle) {
      return false;
    }
    const { html, title, blurb, thumbnail } = article.originalArticle;
    return (
      article.html !== html ||
      article.title !== title ||
      article.blurb !== blurb ||
      article.thumbnail !== thumbnail
    );
  };

  const deleteArticleClick = async article => {
    if (!username || isAdmin) {
      return;
    }
    dispatch(deleteArticle(username, article));
  };

  useEffect(() => {
    const ageInMilliseconds = Date.now() - (timestamp || new Date(0)).getTime();
    if (ageInMilliseconds > 5 * 60 * 1000) {
      dispatch(loadArticles({ username }));
    }
  }, [dispatch, username, timestamp]);

  const ArticleRow = ({ data: article }) => {
    let articleUrl = `/article/${encodeURIComponent(article.articleId)}/edit`;
    if (isAdmin) {
      articleUrl = `/user/${username}/article/${encodeURIComponent(
        article.articleId
      )}/view`;
    }
    return (
      <>
        <th className="align-middle" scope="row">
          {hasUnsavedChanges(article) && (
            <span>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                size="1x"
                title="Unsaved Article"
              />
            </span>
          )}
          <Link className="text-left btn btn-link" to={articleUrl}>
            {article.title || "untitled"}
          </Link>
        </th>
        <td className="text-right align-middle d-none d-md-table-cell">
          <ArticleLastModified article={article} />
        </td>
        {!isAdmin && (
          <td className="text-right align-middle">
            <div className="btn-group" role="group">
              <PublishArticle
                username={username}
                article={article}
                hasUnsavedChanges={hasUnsavedChanges(article)}
              />
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => deleteArticleClick(article)}
              >
                <FontAwesomeIcon icon={faTrashAlt} size="1x" />
              </button>
            </div>
          </td>
        )}
      </>
    );
  };

  return (
    <div className={`text-left ${className}`}>
      <Table
        row={ArticleRow}
        data={draftArticles}
        loading={loading}
        noDataText="No drafts!"
      />
    </div>
  );
};

export default withRouter(ArticleDraftList);
