import React from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Logger from "js-logger";
import ArticleLastModified from "../../common/_article/_lastModified";
import { API_URL } from "../../../scripts/api";
import ArticleTable from "../table";

const ArticlePublishedList = ({ history, className, username, readOnly }) => {
  const unpublishArticle = async ({ publishedArticleId }) => {
    try {
      const url = `${API_URL}/user/${username}/published/article/${publishedArticleId}/unpublish`;
      await axios.post(url, false, {
        validateStatus: status => status === 201
      });

      history.push("/article/draft");
    } catch (error) {
      Logger.error(error);
    }
  };

  const ArticleRow = ({ data: article }) => (
    <>
      <th className="align-middle" scope="row">
        {article.title || "untitled"}
      </th>
      <td className="text-right align-middle">
        <ArticleLastModified article={article} />
      </td>
      {!readOnly && (
        <td className="text-right align-middle">
          <div className="btn-group" role="group">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => unpublishArticle(article)}
            >
              <FontAwesomeIcon icon={faDownload} size="1x" />
            </button>
          </div>
        </td>
      )}
    </>
  );

  return (
    <ArticleTable
      row={ArticleRow}
      className={className}
      url={`user/${username}/published/article`}
    />
  );
};

export default withRouter(ArticlePublishedList);
