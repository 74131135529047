import React, { useMemo } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserEdit,
  faArrowCircleLeft,
  faNewspaper
} from "@fortawesome/free-solid-svg-icons";
import UserArticleDraftList from "../../../../components/common/_article/_draft/_list";
import UserArticlePublishedList from "../../../../components/article/published/list";

const UserArticleList = ({ match, history }) => {
  const username = useMemo(() => {
    return decodeURIComponent(match.params.username);
  }, [match.params]);

  return (
    <div className={`text-left`}>
      <div className="jumbotron jumbotron-fluid bg-primary text-light">
        <Container>
          <h1 className="display-4">
            <span className="pr-2">
              <FontAwesomeIcon icon={faNewspaper} size="1x" />
              <span className="pl-2">Articles</span>
            </span>
          </h1>
          <hr className="my-4" />
          <p className="lead">
            <Link to={`/admin/user/${username}`} className="btn btn-light">
              <FontAwesomeIcon icon={faArrowCircleLeft} size="1x" />
            </Link>
            <Link
              to={`/admin/user/${username}`}
              className="btn btn-link text-light"
            >
              <FontAwesomeIcon icon={faUserEdit} size="1x" />
              <span className="pl-2">{username}</span>
            </Link>
          </p>
        </Container>
      </div>
      <Container>
        <Tabs defaultActiveKey="published" variant="pills">
          <Tab eventKey="published" title="Published">
            <UserArticlePublishedList
              className="row pt-2"
              username={username}
              readOnly
            />
          </Tab>
          <Tab eventKey="drafts" title="Drafts">
            <UserArticleDraftList
              className="row pt-2"
              user={{ username }}
              isAdmin
            />
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

export default UserArticleList;
