import React, { useState } from "react";
import { RichUtils, KeyBindingUtil, EditorState } from "draft-js";
import { Modal, Button, Form } from "react-bootstrap";

export const AddLinkModal = ({ show, onHide, editorState }) => {
  const getHyperlinkUrlFromEditorState = editorState => {
    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(0);
    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);
      const { url } = linkInstance.getData();
      return url;
    }
    return "";
  };
  const [hyperlink, setHyperlink] = useState(
    getHyperlinkUrlFromEditorState(editorState)
  );
  return (
    <Modal show={show} onHide={onHide} animation centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Hyperlink</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Control
            type="text"
            placeholder="hyperlink"
            value={hyperlink}
            onChange={({ target }) => setHyperlink(target.value)}
            required
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onHide(hyperlink)}>
          <span className="">Confirm</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const LinkControl = ({ children, contentState, entityKey }) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      className="link"
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      aria-label={url}
    >
      {children}
    </a>
  );
};

export const LinkStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

export default {
  keyBindingFn(event, { getEditorState }) {
    const editorState = getEditorState();
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
      return;
    }
    if (KeyBindingUtil.hasCommandModifier(event) && event.which === 75) {
      return "add-link";
    }
  },

  handleKeyCommand(command, editorState, { getEditorState, setEditorState }) {
    if (command !== "add-link") {
      return "not-handled";
    }
    let link = window.prompt("Paste the link -");
    const selection = editorState.getSelection();
    if (!link) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null));
      return "handled";
    }
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: link
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    setEditorState(RichUtils.toggleLink(newEditorState, selection, entityKey));
    return "handled";
  },

  decorators: [
    {
      strategy: LinkStrategy,
      component: LinkControl
    }
  ]
};
