import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import jsLogger from "js-logger";
import { LOGOUT } from "../scripts/redux/actions/authenticationActions";
import { API_URL } from "../scripts/api";
import WithAuth from "../components/_library/_auth/withAuth";

const Logout = ({ history }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const logout = async () => {
      try {
        const url = `${API_URL}/logout`;
        jsLogger.debug("Logging user out...", { url });
        const response = await axios.post(url);
        jsLogger.debug("Logged user out!", { response });
        dispatch({ type: LOGOUT });
        history.push("/login");
      } catch (error) {
        history.goBack();
      }
    };
    logout();
  }, [dispatch, history]);
  return (
    <WithAuth>
      <FontAwesomeIcon icon={faCircleNotch} size="10x" spin />
    </WithAuth>
  );
};

export default Logout;
