import React, { useState } from "react";
import { EditorState, RichUtils, AtomicBlockUtils } from "draft-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBold,
  faItalic,
  faUnderline,
  faImage,
  faCode,
  faQuoteLeft,
  faListOl,
  faListUl,
  faSpellCheck,
  faLink,
  faUnlink
} from "@fortawesome/free-solid-svg-icons";
import UploadImageModal from "../_uploadImageModal";
import { AddLinkModal } from "./_linkControl";

const RichTextEditorControls = ({
  articleId,
  editorState,
  setEditorState,
  spellCheck,
  setSpellCheck,
  article,
  setArticle
}) => {
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [showAddLinkModal, setShowAddLinkModal] = useState(false);

  const styles = [
    { key: "BOLD", value: "Bold", icon: faBold },
    { key: "ITALIC", value: "Italic", icon: faItalic },
    { key: "UNDERLINE", value: "Underline", icon: faUnderline },
    { key: "CODE", value: "Code", icon: faCode }
  ];
  const currentStyle = editorState.getCurrentInlineStyle();
  const setRichUtilState = type => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, type));
  };

  const blockTypes = [
    { label: "H1", style: "header-one" },
    { label: "H2", style: "header-two" },
    { label: "H3", style: "header-three" },
    { label: "H4", style: "header-four" },
    { label: "H5", style: "header-five" },
    { label: "H6", style: "header-six" },
    {
      label: "Blockquote",
      icon: faQuoteLeft,
      style: "blockquote",
      hidden: true
    },
    { label: "UL", icon: faListUl, style: "unordered-list-item" },
    { label: "OL", icon: faListOl, style: "ordered-list-item" },
    { label: "Code Block", icon: faCode, style: "code-block", hidden: true }
  ];
  const currentBlockType = editorState
    .getCurrentContent()
    .getBlockForKey(editorState.getSelection().getStartKey())
    .getType();
  const setBlockTypeInEditorState = blockType =>
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));

  const insertImage = (editorState, url) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  const insertHyperlink = (editorState, hyperlink) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const contentWithEntity = contentState.createEntity("LINK", "MUTABLE", {
      url: hyperlink
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    return RichUtils.toggleLink(newEditorState, selection, entityKey);
  };
  const removeHyperlink = editorState => {
    const selection = editorState.getSelection();
    return RichUtils.toggleLink(editorState, selection, null);
  };

  return (
    <>
      {showImageUploader && (
        <UploadImageModal
          articleId={articleId}
          show={showImageUploader}
          onHide={(image = {}) => {
            if (image && image.url) {
              setArticle({ images: [...(article.images || []), image] });
              setEditorState(insertImage(editorState, image.url));
            }
            setShowImageUploader(false);
          }}
        />
      )}
      {!!showAddLinkModal && (
        <AddLinkModal
          show={showAddLinkModal}
          editorState={editorState}
          onHide={hyperlink => {
            if (hyperlink) {
              setEditorState(insertHyperlink(editorState, hyperlink));
            }
            setShowAddLinkModal(false);
          }}
        />
      )}
      <div className="rich-text-editor__controls">
        <div className="btn-group d-inline">
          {blockTypes
            .filter(({ hidden }) => !hidden)
            .map(({ label, icon, style }) => (
              <button
                key={label}
                className={`btn btn-sm btn-${
                  style === currentBlockType ? "dark" : "primary"
                }`}
                onClick={() => setBlockTypeInEditorState(style)}
              >
                {icon ? <FontAwesomeIcon icon={icon} size="1x" /> : label}
              </button>
            ))}
          {styles
            .filter(({ hidden }) => !hidden)
            .map(({ key, icon }) => (
              <button
                key={key}
                className={`btn btn-sm btn-${
                  currentStyle.has(key) ? "dark" : "primary"
                }`}
                onClick={() => setRichUtilState(key)}
              >
                <FontAwesomeIcon icon={icon} size="1x" />
              </button>
            ))}
          <button
            className={`btn btn-sm btn-${spellCheck ? "success" : "primary"}`}
            onClick={() => setSpellCheck(!spellCheck)}
          >
            <FontAwesomeIcon icon={faSpellCheck} size="1x" />
          </button>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => setShowImageUploader(true)}
          >
            <FontAwesomeIcon icon={faImage} size="1x" />
          </button>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => setShowAddLinkModal(true)}
          >
            <FontAwesomeIcon icon={faLink} size="1x" />
          </button>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => setEditorState(removeHyperlink(editorState))}
          >
            <FontAwesomeIcon icon={faUnlink} size="1x" />
          </button>
        </div>
      </div>
    </>
  );
};

export default RichTextEditorControls;
