import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import ArticlePublishedListComponent from "../../../components/article/published/list";

const ArticlePublishedList = ({ history }) => {
  const { loading } = useSelector(state => state.publishedArticleReducer);
  const { user } = useSelector(state => state.authenticationReducer);
  return (
    <div className="text-left">
      <div className="jumbotron jumbotron-fluid bg-success text-light">
        <div className="container">
          <h1 className="display-4">
            <span>Published Articles</span>
            <span className="pull-left">
              &nbsp;
              {loading && <FontAwesomeIcon icon={faSyncAlt} size="1x" spin />}
            </span>
          </h1>
          <p className="lead">
            Welcome back! These are your published articles.
          </p>
        </div>
      </div>
      <div className="container">
        <ArticlePublishedListComponent
          className="row"
          username={user.username}
        />
      </div>
    </div>
  );
};

export default withRouter(ArticlePublishedList);
