import React from "react";
import { Form } from "react-bootstrap";

const ValidationMessage = ({ className, validated, hidden, children }) =>
  hidden ? (
    ""
  ) : (
    <Form.Text
      className={`${className} text-${validated ? "muted" : "danger"}`}
    >
      {children}
    </Form.Text>
  );

export default ValidationMessage;
