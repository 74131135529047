import React, { useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import jsLogger from "js-logger";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faFire } from "@fortawesome/free-solid-svg-icons";
import { getModeratorReviews } from "../../../scripts/redux/actions/moderator/moderatorReviewActions";
import { API_URL } from "../../../scripts/api";
import Table from "../../../components/common/_table";

const ReviewList = () => {
  const [reviewDetails, setReviewDetails] = useReducer(
    (accumulator, currentValue) => ({
      ...accumulator,
      ...currentValue
    }),
    {}
  );
  const { reviews, loading } = useSelector(
    state => state.moderatorReviewReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!reviews && !loading) {
      dispatch(getModeratorReviews());
    }
  });

  const loadReview = async reviewKey => {
    if (!!reviewDetails[reviewKey]) {
      return;
    }
    try {
      setReviewDetails({ [reviewKey]: true });
      const url = `${API_URL}/moderator/review/${encodeURIComponent(
        reviewKey
      )}`;
      jsLogger.debug("Getting review...", { url });
      const { data } = await axios.get(url, {
        withCredentials: true,
        validateStatus: status => status === 200
      });
      jsLogger.debug("Gotten review!", { data });
      setReviewDetails({ [reviewKey]: data });
    } catch (error) {
      setReviewDetails({ [reviewKey]: false });
      jsLogger.error(error);
    }
  };

  const ReviewImage = ({ className, review }) =>
    review && review.url ? (
      <div className={className}>
        <img
          className="img-fluid rounded w-100"
          src={review.url}
          alt={review.url}
        />
      </div>
    ) : (
      <div />
    );

  const ReadOnlyRow = ({ name, value, children }) => {
    const id = name.replace(" ", "-");
    return (
      <>
        <label
          htmlFor={`input-${id}`}
          id={`label-${id}`}
          className="col-sm-3 col-form-label bg-secondary text-nowrap"
        >
          {name}
        </label>
        <div className="col-sm-9">
          {value ? (
            <input
              className="form-control-plaintext text-light"
              id={`input-${id}`}
              aria-describedby={`label-${id}`}
              placeholder={name}
              value={value}
              readOnly
            />
          ) : (
            children
          )}
        </div>
      </>
    );
  };
  const ReviewRow = ({ data: review }) => {
    const { reviewId, reviewKey } = review;
    const reviewDetail = reviewDetails[reviewKey];
    const username = !reviewDetail || reviewDetail.username;
    const percentage = number =>
      `${(number = parseFloat(number * 100).toFixed(2))}%`;
    return (
      <>
        <td>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => loadReview(reviewKey)}
            disabled={reviewDetail === true}
          >
            {reviewId}
          </button>
          {reviewDetail === true && <div className="loading-spinner" />}
          <ReviewImage
            className="d-block d-md-none clearfix mb-2"
            review={reviewDetail}
          />
          {reviewDetail && reviewDetail !== true && (
            <div className="container">
              <form>
                <div className="form-group row">
                  <ReadOnlyRow name="username">
                    <Link
                      className="btn btn-link pl-0"
                      to={`/admin/user/${username}`}
                    >
                      <FontAwesomeIcon icon={faUser} size="1x" />
                      <span className="pl-2">{username}</span>
                    </Link>
                  </ReadOnlyRow>
                  <ReadOnlyRow name="article">
                    <Link
                      className="text-left btn btn-link pl-0"
                      to={`/article/${reviewDetail.articleId}/edit`}
                    >
                      {reviewDetail.articleId}
                    </Link>
                  </ReadOnlyRow>
                  <ReadOnlyRow name="hentai">
                    <FontAwesomeIcon icon={faFire} size="1x" />
                    <span className="pl-1">
                      {percentage(reviewDetail.hentai)}
                    </span>
                  </ReadOnlyRow>
                  <ReadOnlyRow name="porn">
                    <FontAwesomeIcon icon={faFire} size="1x" />
                    <span className="pl-1">
                      {percentage(reviewDetail.porn)}
                    </span>
                  </ReadOnlyRow>
                  <ReadOnlyRow name="sexy">
                    <FontAwesomeIcon icon={faFire} size="1x" />
                    <span className="pl-1">
                      {percentage(reviewDetail.sexy)}
                    </span>
                  </ReadOnlyRow>
                </div>
              </form>
            </div>
          )}
        </td>
        <td className="d-none d-md-block">
          <ReviewImage className="float-right" review={reviewDetail} />
        </td>
      </>
    );
  };

  return (
    <div className="text-left">
      <div className="jumbotron jumbotron-fluid bg-primary text-light">
        <div className="container">
          <h1 className="display-4">
            <span className="pr-2">
              <FontAwesomeIcon icon={faFire} size="1x" />
              <span className="pl-2">Review Queue</span>
            </span>
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <Table
            row={ReviewRow}
            data={reviews}
            loading={loading}
            noDataText="No reviews!"
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewList;
