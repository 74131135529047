import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";

export default ({ article, className }) => {
  return (
    <span className="className">
      {article && article.lastModifiedDate ? (
        <span>
          <FontAwesomeIcon icon={faClock} size="1x" />
          <span className="pl-1">
            Saved {moment(article.lastModifiedDate).fromNow()}
          </span>
        </span>
      ) : (
        <span>
          <FontAwesomeIcon icon={faSyncAlt} size="1x" spin />
        </span>
      )}
    </span>
  );
};
