import React from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSkull } from "@fortawesome/free-solid-svg-icons";

const ValidationTitle = ({ className, validated, children }) => (
  <Form.Label className={`${className} ${!validated && "text-danger"}`}>
    {children}
    {!validated && (
      <span className="pl-1">
        <FontAwesomeIcon icon={faSkull} size="1x" />
      </span>
    )}
  </Form.Label>
);

export default ValidationTitle;
