import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import * as draftArticleActions from "../scripts/redux/actions/draftArticleActions";
import * as publishedArticleActions from "../scripts/redux/actions/publishedArticleActions";
import * as publicArticleActions from "../scripts/redux/actions/articleActions";

const Home = () => {
  const { user } = useSelector(state => state.authenticationReducer);
  const { username } = user;

  const { loading: draftArticlesLoading, draftArticles = [] } =
    useSelector(state => state.draftArticleReducer)[username] || {};
  const { loading: publishedArticlesLoading, publishedArticles = [] } =
    useSelector(state => state.publishedArticleReducer)[username] || {};
  const {
    loading: publicArticlesLoading,
    articles: publicArticles
  } = useSelector(state => state.articleReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(draftArticleActions.loadArticles({ username }));
    dispatch(publishedArticleActions.loadArticles({ username }));
    dispatch(publicArticleActions.loadArticles());
  }, [dispatch, username]);

  const metrics = [
    {
      header: "you have",
      footer: "draft articles",
      colour: "primary",
      value: draftArticles.length,
      loading: draftArticlesLoading,
      link: "/article/draft"
    },
    {
      header: "you have",
      footer: "published articles",
      colour: "success",
      value: publishedArticles.length,
      loading: publishedArticlesLoading,
      link: "/article/published"
    },
    {
      header: "everyone",
      footer: (
        <a
          href="https://www.opencolumnist.com/"
          className="btn btn-link p-0 text-info"
        >
          Open Columnist
        </a>
      ),
      colour: "info",
      value: publicArticles.length,
      loading: publicArticlesLoading
    }
  ];

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h1>Hey, {username}!</h1>
          <p className="lead">Welcome to your dashboard!</p>
          <hr className="my-4 bg-light" />
        </Col>
      </Row>
      <Row>
        {metrics.map(
          ({ header, footer, colour, value, loading, link }, index) => (
            <Col key={index} xs={12} md={4} className="pb-4">
              <div
                className={`card text-center bg-dark border-dark text-${colour} `}
              >
                <div className="card-header">
                  <small>{header}</small>
                </div>
                <div
                  className={`card-body bg-${colour} text-light d-table w-100`}
                  style={{ height: "7em" }}
                >
                  <h1 className="card-title d-table-cell align-middle">
                    {!value && loading ? (
                      <div className="loading-spinner" />
                    ) : (
                      value
                    )}
                  </h1>
                </div>
                <div className="card-footer">
                  {link ? (
                    <Link
                      className={`btn btn-link p-0 text-${colour}`}
                      to={link}
                    >
                      {footer}
                    </Link>
                  ) : (
                    footer
                  )}
                </div>
              </div>
            </Col>
          )
        )}
      </Row>
    </Container>
  );
};

export default Home;
