import { loadProgressBar } from "axios-progress-bar";
import ReactGA from "react-ga";
import jsLogger from "js-logger";
import "./styles/index.scss";
import * as serviceWorker from "./serviceWorker";
import RenderApp from "./index.jsx";

const rootElement = "root";

jsLogger.useDefaults({ logLevel: jsLogger.DEBUG });
jsLogger.debug("Configured logger!", { logLevel: jsLogger.getLevel() });

loadProgressBar({ showSpinner: true, parent: `#${rootElement}` });
ReactGA.initialize("UA-156810117-2");
ReactGA.pageview(window.location.pathname + window.location.search);

RenderApp(rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
