/* jshint maxcomplexity:8 */

export default (
  resourceType,
  initialState = {
    byId: {},
    errored: false,
    ids: [],
    pages: [],
    currentPage: false,
    lastPage: false,
    requested: false,
    timestamp: false
  }
) => {
  const resourceTypeUpperCase = resourceType.toUpperCase();

  const reducer = (
    state = initialState,
    { type, value, id, error, timestamp } = {}
  ) => {
    switch (type) {
      case `START_REQUESTING_${resourceTypeUpperCase}`:
        return {
          ...state,
          requested: true,
          timestamp
        };
      case `ERROR_REQUESTING_${resourceTypeUpperCase}`:
        return {
          ...state,
          errored: error || true,
          requested: false,
          timestamp
        };
      case `FINISH_REQUESTING_${resourceTypeUpperCase}`:
        return {
          ...state,
          requested: false,
          timestamp
        };
      case `ADD_${resourceTypeUpperCase}`:
        return { ...state, byId: { ...state.byId, [id]: value }, timestamp };
      case `DELETE_${resourceTypeUpperCase}`:
        const { byId, ids } = state;
        delete byId[id];

        return {
          ...state,
          byId,
          ids: ids.filter(obj => obj !== id),
          timestamp
        };
      case "LOGOUT":
        return { ...initialState, timestamp };
      default:
        return state;
    }
  };

  return reducer;
};
