import axios from "axios";
import jsLogger from "js-logger";
import { API_URL } from "../../../api";

const SET_ADMIN_USER_LOADING = "SET_ADMIN_USER_LOADING";
const SET_ADMIN_USERS = "SET_ADMIN_USERS";

const getAdminUsers = () => {
  return async dispatch => {
    try {
      dispatch({ type: SET_ADMIN_USER_LOADING, loading: true });
      const url = `${API_URL}/user?limit=1000`;
      jsLogger.debug("Getting users...", { url });
      const { data } = await axios.get(url, {
        withCredentials: true,
        validateStatus: status => status === 200
      });
      jsLogger.debug("Gotten users!", { data });
      dispatch({ type: SET_ADMIN_USERS, data: data.users });
    } catch (error) {
      dispatch({ type: SET_ADMIN_USER_LOADING, loading: false });
      jsLogger.error(error.callStack || error);
    }
  };
};

export { SET_ADMIN_USER_LOADING, SET_ADMIN_USERS, getAdminUsers };
