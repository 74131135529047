import React, { useEffect, useState, useCallback } from "react";
import { Col, ButtonGroup, Button } from "react-bootstrap";
import axios from "axios";
import Logger from "js-logger";
import collect from "collect.js";
import Table from "../common/_table";
import { API_URL } from "../../scripts/api";

const ArticleTable = ({ className, row, url }) => {
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState(false);
  const [pages, setPages] = useState(collect([]));

  const currentPageContext = pages.last();

  const loadArticles = useCallback(
    async pageContext => {
      const getPageContextQueryString = () => {
        const { nextContinuationToken } = pageContext || {};

        if (nextContinuationToken) {
          return { continuationToken: nextContinuationToken };
        }

        return {};
      };

      setLoading(true);

      try {
        const { data } = await axios.get(`${API_URL}/${url}`, {
          params: {
            ...getPageContextQueryString(),
            limit: 10
          }
        });

        const { articles, ...newPageContext } = data;
        return { articles, newPageContext };
      } catch (error) {
        Logger.error(error);
        return {};
      } finally {
        setLoading(false);
      }
    },
    [url]
  );

  const nextPage = useCallback(async () => {
    const { articles, newPageContext } = await loadArticles(currentPageContext);

    setArticles(articles);
    setPages(pages.merge([newPageContext]));
  }, [currentPageContext, pages, loadArticles]);

  const previousPage = async () => {
    pages.pop();
    const previousPageContext = pages.pop();

    const { articles, newPageContext } = await loadArticles(
      previousPageContext
    );

    setArticles(articles);
    setPages(pages.merge([newPageContext]));
  };

  useEffect(() => {
    if (!articles) {
      nextPage();
    }
  }, [articles, nextPage]);

  return (
    <div className={`text-left ${className}`}>
      <Col xs={12}>
        <Table
          row={row}
          data={!loading && articles}
          loading={loading}
          noDataText="No articles yet!"
        />
      </Col>
      {!loading && currentPageContext && (
        <Col xs={12} className="pt-2 text-center">
          <ButtonGroup>
            <Button
              variant="secondary"
              onClick={() => previousPage()}
              disabled={pages.count() === 1}
            >
              Previous Page
            </Button>
            <Button variant="secondary" disabled>
              {pages.count()}
            </Button>
            <Button
              variant="primary"
              onClick={() => nextPage()}
              disabled={!currentPageContext.isTruncated}
            >
              Next Page
            </Button>
          </ButtonGroup>
        </Col>
      )}
    </div>
  );
};

export default ArticleTable;
