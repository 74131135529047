import React, { useEffect, useRef, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSyncAlt,
  faArrowCircleLeft,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import jsLogger from "js-logger";
import deepEqual from "deep-equal";
import RichTextEditor from "../../../common/_richTextEditor";
import ArticleLastModified from "../../../common/_article/_lastModified";
import ArticleInfoEditor from "./articleInfoEditor";
import PublishArticle from "../../../common/_article/_draft/_publish";

const ArticleEditor = ({
  article,
  setArticle,
  saveArticle,
  cacheUnsavedArticle,
  history
}) => {
  const { user } = useSelector(state => state.authenticationReducer);

  const [saving, setSaving] = useState(false);
  const [isContentModified, setIsContentModified] = useState(false);
  const [lastCachedTimestamp, setLastCachedTimestamp] = useState(false);
  const [originalArticle, setOriginalArticle] = useState(article);
  const [lastTryCachedContent, setLastTryCachedContent] = useState(
    article.content
  );

  const maxTitleLength = 60 - " | Open Columnist".length;
  const maxExcerptLength = 150;

  const richTextEditorRef = useRef();

  const checkUnsavedChangesMemo = useMemo(() => {
    let articleToCompare;
    if (isContentModified && richTextEditorRef.current) {
      const content = richTextEditorRef.current.getContent();
      articleToCompare = {
        ...article,
        content: content.content,
        html: content.html
      };
    } else {
      articleToCompare = article;
    }
    return !deepEqual(originalArticle, articleToCompare, { strict: true });
  }, [originalArticle, article, isContentModified]);

  const saveEditor = async close => {
    setSaving(true);
    const content = richTextEditorRef.current.getContent();
    const articleToSave = {
      ...article,
      content: content.content,
      html: content.html
    };

    try {
      setLastCachedTimestamp(new Date().toUTCString());
      await cacheUnsavedArticle({
        ...articleToSave,
        lastCachedTimestamp: lastCachedTimestamp
      });
      const savedArticle = await saveArticle(articleToSave, !!close);
      setOriginalArticle(savedArticle);
    } catch (error) {
      jsLogger.error(error);
    }

    if (!close) {
      jsLogger.debug("Save complete! Setting save to false...");
      setSaving(false);
    }
  };

  useEffect(() => {
    const tryCachingArticle = async (articleToCache, lastCached) => {
      if (!lastCached || Date.now() - new Date(lastCached) >= 15000) {
        setLastCachedTimestamp(new Date().toUTCString());
        await cacheUnsavedArticle({
          ...articleToCache,
          lastCachedTimestamp: lastCached
        });
      }
    };

    let articleToCompare;
    if (lastTryCachedContent) {
      articleToCompare = {
        ...article,
        content: lastTryCachedContent.content,
        html: lastTryCachedContent.html
      };
    } else {
      articleToCompare = article;
    }
    tryCachingArticle(articleToCompare, lastCachedTimestamp);
  }, [article, lastTryCachedContent, lastCachedTimestamp, cacheUnsavedArticle]);

  const validateTitle = article.title.length <= maxTitleLength;
  const validateExcerpt = article.blurb.length <= maxExcerptLength;
  const validationPassedMemo = useMemo(() => validateTitle && validateExcerpt, [
    validateTitle,
    validateExcerpt
  ]);

  return (
    <Container>
      <Row className="my-4">
        <Col xs={4}>
          <button
            className="btn btn-link float-left"
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} size="2x" />
          </button>
        </Col>
        <Col xs={4}>
          <h2 className="pl-2 pr-2 mt-1">Article</h2>
        </Col>
        <Col xs={4} className="my-auto">
          <div className="float-right">
            {saving ? (
              <FontAwesomeIcon icon={faSyncAlt} size="2x" spin />
            ) : article.articleId && checkUnsavedChangesMemo ? (
              <div className="text-nowrap">
                <FontAwesomeIcon icon={faExclamationCircle} size="1x" />
                <span className="pl-2">Unsaved changes</span>
              </div>
            ) : (
              <ArticleLastModified article={article} />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="btn-group btn-block" role="group">
            <button
              type="button"
              className="btn btn-dark"
              onClick={() => saveEditor(false)}
              disabled={saving || !validationPassedMemo}
            >
              <span>Save</span>
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => saveEditor(true)}
              disabled={saving || !validationPassedMemo}
            >
              <span>Save & Close</span>
            </button>
          </div>
        </Col>
      </Row>
      <hr className="my-4" />
      <fieldset disabled={saving}>
        <Tabs defaultActiveKey="articleArticle" variant="pills">
          <Tab eventKey="articleArticle" title="Article">
            <Row>
              <Col xs={12}>
                <RichTextEditor
                  ref={richTextEditorRef}
                  articleId={article ? article.articleId : undefined}
                  content={article ? article.content : undefined}
                  disabled={saving}
                  setIsContentModified={isModified => {
                    if (
                      richTextEditorRef.current &&
                      (isModified || isContentModified)
                    ) {
                      const content = richTextEditorRef.current.getContent();
                      if (!deepEqual(lastTryCachedContent, content)) {
                        setLastTryCachedContent(content);
                      }
                    }
                    setIsContentModified(isModified);
                  }}
                  article={article}
                  setArticle={setArticle}
                />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="articlePreview" title="Preview">
            <Row className="pt-2">
              <Col xs={12} className="bg-white text-dark text-left p-3 rounded">
                <span dangerouslySetInnerHTML={{ __html: article.html }} />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="articlePublish" title="Publish">
            <Row>
              <Col xs={12}>
                <ArticleInfoEditor
                  className="pt-2"
                  article={article}
                  setArticle={setArticle}
                />
              </Col>
              <Col xs={12} className="text-left">
                <PublishArticle
                  variant="warning"
                  article={article}
                  text="Publish Article"
                  username={user.username}
                  saveBeforePublishing
                />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </fieldset>
    </Container>
  );
};

export default withRouter(ArticleEditor);
