import axios from "axios";
import jsLogger from "js-logger";
import { API_URL } from "../../api";

const SET_LOADING_ARTICLES = "SET_LOADING_ARTICLES";
const SUCCESS_LOADING_ARTICLES = "SUCCESS_LOADING_ARTICLES";

const loadArticles = ({ limit = 1000 } = {}) => {
  return async dispatch => {
    try {
      dispatch({ type: SET_LOADING_ARTICLES, loading: true });
      const url = `${API_URL}/article?limit=${limit}`;
      jsLogger.debug("Getting articles list from API...", { url });
      const { data } = await axios.get(url, {
        validateStatus: status => status === 200
      });
      jsLogger.debug("Gotten articles!", { data });
      dispatch({
        type: SUCCESS_LOADING_ARTICLES,
        loading: false,
        value: data,
        timestamp: new Date()
      });
    } catch (error) {
      dispatch({ type: SET_LOADING_ARTICLES, loading: false });
      jsLogger.error(error.callStack || error);
    }
  };
};

export { SET_LOADING_ARTICLES, loadArticles };
