import axios from "axios";
import jsLogger from "js-logger";
import { API_URL } from "../../../api";

const ADD_INVITATION = "ADD_INVITATION";
const SET_LOADING_INVITATIONS = "SET_LOADING_INVITATIONS";
const SUCCESS_LOADING_INVITATIONS = "SUCCESS_LOADING_INVITATIONS";

const loadUserInvitations = (user) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING_INVITATIONS, loading: true });
      const url = `${API_URL}/user/${user.username}/invitation`;
      jsLogger.debug("Getting invitations...", { url });
      const { data } = await axios.get(url, {
        withCredentials: true,
        validateStatus: (status) => status === 200,
      });
      jsLogger.debug("Gotten invitations!", { data });
      dispatch({ type: SUCCESS_LOADING_INVITATIONS, value: { invitations: data } });
    } catch (error) {
      dispatch({ type: SET_LOADING_INVITATIONS, loading: false });
      jsLogger.error(error.callStack || error);
    }
  };
};

const createUserInvitation = (user) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING_INVITATIONS, loading: true });
      const url = `${API_URL}/user/${user.username}/invitation`;
      jsLogger.debug("Posting invitation...", { url });
      const { data } = await axios.post(url, {
        withCredentials: true,
        validateStatus: (status) => status === 201,
      });
      jsLogger.debug("Posted invitation!", { data });
      dispatch({ type: ADD_INVITATION, value: data });
    } catch (error) {
      dispatch({ type: SET_LOADING_INVITATIONS, loading: false });
      jsLogger.error(error.callStack || error);
    }
  };
};

export { loadUserInvitations, createUserInvitation };
