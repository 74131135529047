import crudReducer from "./crudReducer";
const initialUserState = {
  loading: false,
  timestamp: null,
  publishedArticles: []
};
const initialState = {};

const userReducer = crudReducer(
  "publishedArticles",
  "articleId",
  initialUserState
);

const publishedArticleReducer = (
  state = initialState,
  { username, ...value } = {}
) => ({
  ...state,
  [username]: userReducer(state[username], value)
});

export default publishedArticleReducer;
