import React, { useState, useMemo, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { EditorState, convertFromRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";
import { useSelector, useDispatch } from "react-redux";
import jsLogger from "js-logger";
import collect from "collect.js";
import { loadArticles } from "../../../scripts/redux/actions/draftArticleActions";

const UserArticleView = ({ history, match }) => {
  const { articleId, username } = useMemo(
    () => ({
      articleId: decodeURIComponent(match.params.articleId),
      username: decodeURIComponent(match.params.username)
    }),
    [match.params]
  );

  const { [username]: articleState = {} } = useSelector(
    state => state.draftArticleReducer
  );
  const { draftArticles } = articleState;
  const [article, setArticle] = useState(null);

  const editorStateMemo = useMemo(
    () =>
      article && article.content
        ? EditorState.createWithContent(convertFromRaw(article.content))
        : EditorState.createEmpty(),
    [article]
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (article === false) {
      history.goBack();
      return;
    }

    if (!articleId) {
      jsLogger.warn("Cannot get article: articleId is invalid!", {
        articleId
      });
      setArticle(false);
      return;
    }

    const findArticle = (articles, articleId) => {
      jsLogger.trace("Searching for article from cache...", { articleId });
      return collect(articles).firstWhere("articleId", articleId);
    };

    jsLogger.debug("Getting article from cache...", { articleId });
    const articleFromCache = findArticle(draftArticles, articleId);
    if (articleFromCache) {
      jsLogger.debug("Article found!", { articleFromCache });
      setArticle(articleFromCache);
    } else {
      jsLogger.debug("Article not found - reloading cache!", { articleId });
      setArticle(false);
      dispatch(
        loadArticles({ username }, (articles, error) => {
          if (error) {
            window.alert(error.callStack || error);
          } else if (!findArticle(draftArticles, articleId)) {
            window.alert("Cannot get article from API!");
          }
        })
      );
    }
  }, [article, draftArticles, articleId, username, dispatch, history]);

  return (
    <Container>
      {article && (
        <>
          <h1>{article.title}</h1>
          <h2>{article.blurb}</h2>
          <Row className="bg-light text-dark p-2">
            <Editor editorState={editorStateMemo} readOnly />
          </Row>
        </>
      )}
    </Container>
  );
};

export default UserArticleView;
