import React, { useReducer, useMemo, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import jsLogger from "js-logger";
import {
  loadArticles,
  putArticle,
  cacheArticle
} from "../../../../scripts/redux/actions/draftArticleActions";
import ArticleEditor from "../../../../components/article/draft/editor/articleEditor";

const EditArticleEditor = ({ match, history }) => {
  const { articleId } = match.params;
  const articleIdMemo = useMemo(() => {
    jsLogger.debug("Decoding articleId...", { articleId });
    if (articleId) {
      return decodeURIComponent(articleId);
    } else {
      return false;
    }
  }, [articleId]);

  const { user } = useSelector(state => state.authenticationReducer);
  const dispatch = useDispatch();

  const { draftArticles = [], loading } =
    useSelector(state => state.draftArticleReducer)[user.username] || {};
  const [article, setArticle] = useReducer(
    (accumulator, value) => ({
      ...accumulator,
      ...value
    }),
    false
  );

  useEffect(() => {
    dispatch(loadArticles(user));
  }, [dispatch, user]);

  useEffect(() => {
    const handleFailure = () => {
      jsLogger.warn("Cannot get article: articleId is invalid!", {
        articleIdMemo,
        draftArticles
      });
      history.goBack();
    };

    if (loading) {
      return;
    }

    if (!articleIdMemo) {
      handleFailure();
      return;
    }

    if (draftArticles.length) {
      const articleFound = draftArticles.find(
        ({ articleId }) => articleId === articleIdMemo
      );
      if (!articleFound) {
        handleFailure();
        return;
      }
      setArticle(articleFound);
    }
  }, [loading, articleIdMemo, draftArticles, history]);

  const saveArticle = async (article, close) => {
    return new Promise((resolve, reject) => {
      dispatch(
        putArticle(
          { username: user.username, article, articleId: articleIdMemo },
          (article, error) => {
            if (error) {
              reject(error);
              window.alert(error.callStack || error);
            } else if (close) {
              resolve(article);
              history.goBack();
            } else {
              setArticle(article);
              resolve(article);
            }
          }
        )
      );
    });
  };

  const cacheUnsavedArticle = async unsavedArticle => {
    return new Promise((resolve, reject) => {
      dispatch(
        cacheArticle(user, unsavedArticle, (cachedArticle, error) => {
          if (error) {
            reject(error);
            window.alert(error.callStack || error);
          } else {
            if (cachedArticle) {
              setArticle(cachedArticle);
            }
            resolve(cachedArticle);
          }
        })
      );
    });
  };

  if (!article) {
    return <div className="loading-spinner big centre" />;
  }

  return (
    <ArticleEditor
      article={article}
      setArticle={setArticle}
      saveArticle={saveArticle}
      cacheUnsavedArticle={cacheUnsavedArticle}
    />
  );
};

export default withRouter(EditArticleEditor);
