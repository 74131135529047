import React, { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";
import ValidationTitle from "../common/_form/_validationTitle";
import ValidationMessage from "../common/_form/_validationMessage";
import { putUserAuth } from "../../scripts/redux/actions/user/userAuthenticationActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import jsLogger from "js-logger";

const PasswordForm = ({ username }) => {
  const { loading } = useSelector(state => state.userAuthenticationReducer);

  const dispatch = useDispatch();

  const [formData, setFormData] = useReducer(
    (accumulator, { key, value }) => ({
      ...accumulator,
      [key]: value
    }),
    {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    }
  );

  const [validation, setValidation] = useReducer(
    (accumulator, currentValue) => ({
      ...accumulator,
      ...currentValue
    }),
    { newPassword: true, confirmNewPassword: true }
  );

  const handleFormDataChange = ({ target }) => {
    jsLogger.trace("Updating form data...", { target });
    setFormData({ key: target.name, value: target.value });
    jsLogger.trace("Updated form data!", { formData });
  };

  const putPassword = async event => {
    event.preventDefault();
    event.stopPropagation();

    const { newPassword, confirmNewPassword } = formData;

    const currentValidation = {
      newPassword: /^.{8,2048}$/iu.test(newPassword),
      confirmNewPassword: confirmNewPassword === newPassword
    };

    setValidation(currentValidation);

    if (
      !currentValidation.newPassword ||
      !currentValidation.confirmNewPassword
    ) {
      jsLogger.warn("Validation failed!", { currentValidation });
      return false;
    }

    jsLogger.debug("Updating password...", { username });
    dispatch(putUserAuth({ username, auth: formData }));
  };

  return (
    <Form onSubmit={putPassword}>
      <fieldset disabled={loading}>
        <Form.Row className="form-group pt-2">
          <Form.Label className="col-form-label text-nowrap" hidden>
            Username
          </Form.Label>
          <Form.Control
            name="username"
            id="input-username"
            defaultValue={username}
            aria-describedby={`label-username`}
            autoComplete="username"
            readOnly
            hidden
          />
          <Form.Label className="col-form-label text-nowrap">
            Current Password
          </Form.Label>
          <Form.Control
            className="mb-2"
            id={`input-current-password`}
            aria-describedby={`label-current-password`}
            type="password"
            placeholder={"Current Password"}
            value={formData.currentPassword}
            onChange={handleFormDataChange}
            name={"currentPassword"}
            autoComplete="current-password"
            required
          />
          <ValidationTitle
            validated={validation.newPassword}
            className="col-form-label text-nowrap"
          >
            New Password
          </ValidationTitle>
          <ValidationMessage
            validated={validation.newPassword}
            className="col-sm-12 pb-2"
          >
            <span className="pr-1">
              between 8 and 2048 characters long, white space acceptable:
            </span>
            <a
              href="https://www.useapassphrase.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              try a pass phrase!
            </a>
          </ValidationMessage>
          <Form.Control
            className="mb-2"
            id={`input-new-password`}
            aria-describedby={`label-new-password`}
            type="password"
            placeholder={"New Password"}
            value={formData.newPassword}
            onChange={handleFormDataChange}
            name={"newPassword"}
            autoComplete="new-password"
            required
          />
          <ValidationTitle
            validated={validation.confirmNewPassword}
            className="col-form-label text-nowrap"
          >
            Confrim New Password
          </ValidationTitle>
          <ValidationMessage
            validated={validation.confirmNewPassword}
            hidden={validation.confirmNewPassword}
            className="col-sm-12 pb-2"
          >
            <span className="pr-1">Passwords must match!</span>
          </ValidationMessage>
          <Form.Control
            className="mb-2"
            id={`input-confirm-new-password`}
            aria-describedby={`label-confirm-new-password`}
            type="password"
            placeholder={"Confirm New Password"}
            value={formData.confirmNewPassword}
            onChange={handleFormDataChange}
            name={"confirmNewPassword"}
            autoComplete="new-password"
            required
          />
        </Form.Row>
        <Button variant="warning" type="submit">
          {loading ? (
            <FontAwesomeIcon className="mr-2" icon={faSyncAlt} />
          ) : (
            <FontAwesomeIcon className="mr-2" icon={faSave} />
          )}
          Update Password
        </Button>
      </fieldset>
    </Form>
  );
};

export default PasswordForm;
