import React, { useEffect, useState } from "react";
import jsLogger from "js-logger";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSkull, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { API_URL } from "../scripts/api";
import Logo from "../logo.png";
import CreateUser from "../components/user/createUser";

const RedeemInvitation = ({ match }) => {
  document.title = "Invitation | Open Columnist";
  const { invitationCode } = match.params;
  const [valid, setValid] = useState(null);

  useEffect(() => {
    const getValid = async () => {
      try {
        const url = `${API_URL}/invitation/${invitationCode}/authorise`;
        jsLogger.debug("Getting authorisation for invitation code...", { url });
        const { data } = await axios.get(url, {
          withCredentials: false,
          validateStatus: status => status === 200
        });
        jsLogger.debug("gotten authorisation for invitation code!", { data });
        setValid(!!data);
      } catch (error) {
        setValid(false);
        jsLogger.error(error);
      }
    };
    if (invitationCode) {
      setValid(null);
      getValid();
    } else {
      setValid(false);
    }
  }, [invitationCode]);

  const getBody = () => {
    if (valid === null) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12 mb-2">
              <h1 className="display-4">Validating invitation code...</h1>
            </div>
            <div className="col-12">
              <FontAwesomeIcon icon={faCircleNotch} size="10x" spin />
            </div>
          </div>
        </div>
      );
    } else if (valid === false) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12 mb-2">
              <h1 className="display-4">Invalid invitation code!</h1>
            </div>
            <div className="col-12">
              <FontAwesomeIcon icon={faSkull} size="10x" />
            </div>
          </div>
        </div>
      );
    } else if (valid === true) {
      return <CreateUser invitationCode={invitationCode} />;
    }
    return <div />;
  };

  return (
    <>
      <div className="jumbotron jumbotron-fluid bg-dark">
        <div className="container">
          <div className="">
            <img src={Logo} width="100%" alt="Open Columnist" />
          </div>
        </div>
      </div>
      {getBody()}
    </>
  );
};

export default RedeemInvitation;
