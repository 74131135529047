import CrudReducer from "./crudReducer";

export default (
  resourceType = "",
  resourceIdKey = "",
  initialState = {
    loading: false
  }
) => (
  state = initialState,
  { type, key = "default", value, loading, timestamp } = {}
) => {
  const crudReducer = CrudReducer(
    resourceType,
    resourceIdKey,
    state[key] || false
  );
  return {
    ...state,
    [key]: {
      ...state[key],
      ...crudReducer(state[key], {
        type,
        value,
        loading,
        timestamp
      })
    }
  };
};
