import axios from "axios";
import jsLogger from "js-logger";
import { API_URL } from "../../../api";

const baseUrl = `${API_URL}/inquest`;

const SUCCESS_LOADING_INQUESTS = "SUCCESS_LOADING_INQUESTS";
const SET_LOADING_INQUESTS = "SET_LOADING_INQUESTS";
const DELETE_INQUEST = "DELETE_INQUEST";

const getAdminInquests = () => {
  return async dispatch => {
    try {
      dispatch({ type: SET_LOADING_INQUESTS, loading: true });
      jsLogger.debug("Getting inquests...", { baseUrl });
      const { data } = await axios.get(baseUrl, {
        withCredentials: true,
        validateStatus: status => status === 200
      });
      jsLogger.debug("Gotten inquests!", { data });
      dispatch({
        type: SUCCESS_LOADING_INQUESTS,
        value: data,
        loading: false
      });
    } catch (error) {
      dispatch({
        type: SUCCESS_LOADING_INQUESTS,
        value: { inquests: [] },
        loading: false
      });
      jsLogger.error(error.callStack || error);
    }
  };
};

const deleteAdminInquest = ({ inquest }) => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_LOADING_INQUESTS,
        loading: true
      });
      jsLogger.debug('Deleting inquest...', { baseUrl, inquest });
      await axios.delete(baseUrl, {
        data: inquest,
        withCredentials: true,
        validateStatus: status => status === 200
      });
      jsLogger.debug('Deleted inquest!', { inquest });
      dispatch({
        type: DELETE_INQUEST,
        value: inquest,
        loading: false
      });
    } catch (error) {
      dispatch({
        type: SET_LOADING_INQUESTS,
        loading: false
      });
      jsLogger.error(error);
    }
  };
};

export {
  getAdminInquests,
  deleteAdminInquest
};
