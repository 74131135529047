import {
  SET_USER_APIKEYS,
  SET_USER_APIKEYS_LOADING,
  ADD_USER_APIKEY,
  DELETE_USER_APIKEY
} from "../../actions/user/apiKeyActions.js";

const initialState = {
  apiKeys: [],
  loading: false
};

const userApiKeyReducer = (
  state = initialState,
  { type, data, loading } = {}
) => {
  switch (type) {
    case SET_USER_APIKEYS_LOADING:
      return { ...state, loading };
    case SET_USER_APIKEYS:
      return { ...state, apiKeys: data, loading: false };
    case ADD_USER_APIKEY:
     return { ...state, apiKeys: [ ...state.apiKeys, data ], loading: false };
   case DELETE_USER_APIKEY:
     return {
       ...state,
       apiKeys: state.apiKeys.filter(
         ({ key }) => key !== data.key
       )
     };
    default:
      return state;
  }
};

export default userApiKeyReducer;
