import React from "react";
import { withRouter } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import { Button, Form, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../logo.png";
import WithModerator from "./_library/_auth/withModerator";
import WithAdmin from "./_library/_auth/withAdmin";

const Header = ({ history }) => {
  history.listen(location => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const { user } = useSelector(state => state.authenticationReducer);
  const { username } = user;

  const NavLink = ({ children, to }) => (
    <LinkContainer to={to}>
      <Nav.Link>{children}</Nav.Link>
    </LinkContainer>
  );

  const NavDropdownItem = ({ children, to }) => (
    <LinkContainer to={to}>
      <NavDropdown.Item>{children}</NavDropdown.Item>
    </LinkContainer>
  );

  return (
    <div className="text-left">
      <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
        <Navbar.Brand href="#" onClick={() => history.push("/")}>
          <img
            src={Logo}
            height="30px"
            className="d-inline-block align-top"
            alt="Open Columnist logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown title="Articles" id="nav-dropdown">
              <NavDropdownItem to="/article/draft">Drafts</NavDropdownItem>
              <NavDropdownItem to="/article/published">
                Published
              </NavDropdownItem>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as="a"
                href={`https://www.opencolumnist.com/en/${username}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open Columnist
              </NavDropdown.Item>
            </NavDropdown>
            <WithModerator>
              <NavDropdown title="Moderator" id="nav-dropdown">
                <NavDropdownItem to="/moderator/review">Review</NavDropdownItem>
              </NavDropdown>
            </WithModerator>
            <WithAdmin>
              <NavDropdown title="Admin" id="nav-dropdown">
                <NavDropdownItem to="/admin/user">Users</NavDropdownItem>
                <NavDropdownItem to="/admin/inquest">Inquests</NavDropdownItem>
              </NavDropdown>
            </WithAdmin>
          </Nav>
          <Form inline hidden>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-info">Search</Button>
          </Form>
          <Nav className="navbar-right">
            <NavLink to="/profile">Hey {username}!</NavLink>
            <NavLink to="/logout">
              <FontAwesomeIcon icon={faSignOutAlt} size="1x" />
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default withRouter(Header);
