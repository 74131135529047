import React, { useEffect } from "react";
import jsLogger from "js-logger";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCopy } from "@fortawesome/free-solid-svg-icons";
import {
  createUserInvitation,
  loadUserInvitations
} from "../../scripts/redux/actions/user/userInvitationActions";
import Table from "../common/_table";

const UserInvitations = ({ className }) => {
  const { user } = useSelector(state => state.authenticationReducer);
  const { invitations, loading } = useSelector(state => state.userInvitationReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!invitations && !loading) {
      dispatch(loadUserInvitations(user));
    }
  }, [dispatch, user, loading, invitations]);

  const copyInviationCodeToClipboard = invitationCode => {
    const copyText = document.getElementById(
      `invitation-code__${invitationCode}`
    );
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    jsLogger.debug("Copied invitation code to clipboard!", {
      invitationCode,
      copyText
    });
  };

  const getInvitationUrl = invitationCode =>
    `${window.location.origin}/redeem-invitation/${invitationCode}`;

  const InvitationRow = ({ data }) => (
    <td className="align-middle">
      <div className="input-group">
        <input
          id={`invitation-code__${data.invitationCode}`}
          className="form-control-plaintext text-white pr-2"
          type="text"
          value={getInvitationUrl(data.invitationCode)}
          readOnly
        />
        <div className="input-group-append">
          <button
            style={{ borderRadius: "0.25em" }}
            className="input-group-text btn btn-primary"
            onClick={() => copyInviationCodeToClipboard(data.invitationCode)}
          >
            <FontAwesomeIcon icon={faCopy} size="1x" />
          </button>
        </div>
      </div>
    </td>
  );

  return (
    <Row className={className}>
      <Col lg>
        <p className="lead">
          Here are all the invitations you've already sent out: spread the word!
        </p>
        <button
          className="btn btn-light"
          onClick={() => dispatch(createUserInvitation(user))}
          disabled={loading}
        >
          <FontAwesomeIcon icon={faPlus} size="1x" />
          <span className="pl-2">Create new invitation</span>
        </button>
        <hr className="my-4" />
      </Col>
      <Col lg>
        <Table
          row={InvitationRow}
          data={invitations}
          loading={loading}
          noDataText="No invitations!"
        />
      </Col>
    </Row>
  );
};

export default UserInvitations;
