import React, { useReducer, useState } from "react";
import ReactGA from "react-ga";
import { Button, Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faSkull } from "@fortawesome/free-solid-svg-icons";
import emailValidator from "email-validator";
import md5 from "js-md5";
import jsLogger from "js-logger";
import axios from "axios";
import { API_URL } from "../../scripts/api";
import ValidationTitle from "../common/_form/_validationTitle";
import ValidationMessage from "../common/_form/_validationMessage";

const CreateUser = ({ invitationCode }) => {
  document.title = "Create User | Open Columnist";

  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useReducer(
    (accumulator, currentValue) => ({
      ...accumulator,
      ...currentValue
    }),
    { password: "" }
  );
  const [user, setUser] = useReducer(
    (accumulator, currentValue) => ({
      ...accumulator,
      ...currentValue
    }),
    { username: "", emailAddress: "" }
  );
  const [validationMessage, setValidationMessage] = useState(false);
  const [validation, setValidation] = useReducer(
    (accumulator, currentValue) => ({
      ...accumulator,
      ...currentValue
    }),
    { username: true, emailAddress: true, password: true }
  );

  const handleSubmit = async event => {
    event.preventDefault();
    event.stopPropagation();

    const { username, emailAddress } = user;
    const { password } = credentials;

    const validation = {
      username: /^[a-z\d]{1,43}$/iu.test(username),
      emailAddress: emailValidator.validate(emailAddress),
      password: /^.{8,2048}$/iu.test(password)
    };
    setValidation(validation);
    if (
      !validation.username ||
      !validation.emailAddress ||
      !validation.password
    ) {
      jsLogger.warn("Validation failed!", { validation });
      return false;
    }

    setLoading(true);
    try {
      const url = `${API_URL}/invitation/${invitationCode}/user`;
      jsLogger.debug("Posting user...", {
        user,
        credentials: { ...credentials, password: !!password }
      });
      const passwordMD5 = md5(password);
      const { data, status } = await axios.post(
        url,
        { user, credentials: { passwordMD5 } },
        {
          withCredentials: false,
          validateStatus: status => status === 201 || status === 400
        }
      );
      ReactGA.event({
        category: "conversions",
        action: "signups",
        label: "account",
        value: emailAddress,
        nonInteraction: false
      });
      jsLogger.debug("Posted user!", { data, status });
      if (status === 400) {
        jsLogger.warn("Server side valdiation failed!", { data });
        setValidationMessage(data.message);
        setLoading(false);
        return false;
      }
      window.location = `${window.location.origin}/login`;
    } catch (error) {
      jsLogger.error(error);
      setLoading(false);
    }
  };

  return (
    <div className="container text-left">
      <Row>
        <Col>
          <p className="lead">Great to see you want to join us!</p>
          <span className="lead w-100">
            Just a few quick questions and you'll have yourself a brand new
            account!
            <hr className="my-4 bg-light" />
          </span>
        </Col>
      </Row>
      <Form className="text-left" onSubmit={handleSubmit}>
        <fieldset disabled={loading}>
          <Row className="pb-2">
            <Form.Group as={Col} xs="12" controlId="formUsername">
              <ValidationTitle validated={validation.username}>
                username
              </ValidationTitle>
              <Form.Control
                type="text"
                placeholder="username"
                value={user.username}
                onChange={({ target }) => setUser({ username: target.value })}
                required
              />
              <Form.Text className="text-muted d-inline pr-1">
                this will be visible on all article URLs and contents;
              </Form.Text>
              <ValidationMessage
                className="d-inline"
                validated={validation.username}
              >
                alpha-numeric only, between 1 and 43 characters long
              </ValidationMessage>
            </Form.Group>
            <Form.Group as={Col} xs="12" controlId="formEmail">
              <ValidationTitle validated={validation.emailAddress}>
                email address
              </ValidationTitle>
              <Form.Control
                type="email"
                placeholder="email address"
                value={user.emailAddress}
                onChange={({ target }) =>
                  setUser({ emailAddress: target.value })
                }
                required
              />
              <ValidationMessage validated={validation.emailAddress}>
                {!validation.emailAddress && "not valid email address"}
              </ValidationMessage>
            </Form.Group>
            <Form.Group as={Col} xs="12" controlId="formpassword">
              <ValidationTitle validated={validation.password}>
                password
              </ValidationTitle>
              <Form.Control
                type="password"
                placeholder="password"
                value={credentials.password}
                onChange={({ target }) =>
                  setCredentials({ password: target.value })
                }
                required
              />
              <ValidationMessage validated={validation.password}>
                <span className="pr-1">
                  between 8 and 2048 characters long, white space acceptable:
                </span>
                <a
                  href="https://www.useapassphrase.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  try a pass phrase!
                </a>
              </ValidationMessage>
            </Form.Group>
          </Row>
          {validationMessage && (
            <Row>
              <Form.Group as={Col} xs="12">
                <div className="alert alert-danger">
                  <span className="pr-2">
                    <FontAwesomeIcon icon={faSkull} size="1x" />
                  </span>
                  <span>{validationMessage}</span>
                </div>
              </Form.Group>
            </Row>
          )}
          <Row>
            <Form.Group as={Col} xs="12">
              <Button type="submit" block>
                {loading && (
                  <>
                    <FontAwesomeIcon icon={faCircleNotch} size="1x" spin />
                    <span className="pl-1">Becoming an Open Columnist...</span>
                  </>
                )}
                {!loading && <span>Become an Open Columnist!</span>}
              </Button>
            </Form.Group>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default CreateUser;
