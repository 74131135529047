import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSyncAlt,
  faCircleNotch,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import axios from "axios";
import jsLogger from "js-logger";
import * as nsfwjs from "nsfwjs";
import { API_URL } from "../../scripts/api";

const UploadImageModal = ({ articleId, show, onHide }) => {
  const imageElementId = "upload-image-modal__image-element";

  const { user } = useSelector(state => state.authenticationReducer);

  const [file, setFile] = useState(false);
  const [image, setImage] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [imageClassifications, setImageClassifications] = useState(false);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      jsLogger.debug("Processing image for local display...", { file });
      reader.onloadend = async () => {
        const { result } = reader;
        jsLogger.debug("Finished processing image!", { result });
        setImage(result);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  useEffect(() => {
    const predictImageNSFW = async () => {
      setLoading(true);
      jsLogger.debug("Loading nsfw model...");
      const imageElement = document.getElementById(imageElementId);
      const model = await nsfwjs.load();
      const predictions = await model.classify(imageElement);
      jsLogger.debug("NSFW model predirected!", { predictions });
      setImageClassifications(predictions);
      setLoading(false);
    };
    if (image) {
      predictImageNSFW();
    }
  }, [image]);

  const uploadImageFile = async linkToArticleId => {
    if (loading || uploading) {
      return;
    }

    try {
      setUploading(true);
      const { name: filename, type: filetype } = file;
      const encodedFilename = encodeURIComponent(filename);

      jsLogger.trace("Getting image dimensions...");
      const {
        naturalWidth: width,
        naturalHeight: height
      } = document.querySelector(".upload-image-modal img.image-preview");

      const body = {
        filetype,
        imageClassifications,
        size: { width, height },
        name
      };

      const url = `${API_URL}/user/${user.username}/draft/article/image/${encodedFilename}`;
      jsLogger.debug("Putting signed post to upload thumbnail...", {
        url,
        body
      });
      const { data } = await axios.put(
        url,
        { ...body, linkToArticleId },
        { statusValidation: status => status === 201 }
      );

      const { signedUrl, imageUrl, headers } = data;
      jsLogger.debug(
        "Putted signed post to upload thumbnail! Putting thumbnail in S3...",
        { signedUrl }
      );

      await axios.put(signedUrl, file, {
        withCredentials: false,
        statusValidation: status => status === 200,
        onUploadProgress: progressEvent =>
          jsLogger.debug("Uploading...", { progressEvent }),
        headers
      });
      jsLogger.debug("Posted thumbnail to S3!", { imageUrl });
      onHide({ ...body, url: imageUrl });
    } catch (error) {
      jsLogger.error(error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Modal
      className="upload-image-modal"
      show={show}
      onHide={onHide}
      animation
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="custom-file">
          <label
            className="custom-file-label"
            htmlFor="thumbnailUploader"
            style={{ overflow: "hidden" }}
          >
            {file ? file.name : "Choose image file..."}
          </label>
          <input
            type="file"
            className="custom-file-input"
            id="thumbnailUploader"
            onChange={({ target }) => setFile(target.files[0])}
            required
          />
        </div>
        {image && (
          <div className="form-group mt-2">
            <label htmlFor="imageName">Image Name</label>
            <input
              type="text"
              className="form-control"
              id="imageName"
              value={name}
              placeholder={"Add a descriptive image name"}
              onChange={({ target }) => setName(target.value)}
              required
            />
          </div>
        )}
        {file && image && (
          <img
            className="mt-4 image-preview"
            id={imageElementId}
            src={image}
            alt={file.name}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => uploadImageFile(articleId)}
          disabled={loading || uploading}
        >
          {loading && <FontAwesomeIcon icon={faCircleNotch} size="1x" spin />}
          {uploading && <FontAwesomeIcon icon={faSyncAlt} size="1x" spin />}
          {!loading && !uploading && (
            <FontAwesomeIcon icon={faUpload} size="1x" />
          )}
          <span className="pl-2">Upload</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadImageModal;
