import React from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import {
  publishArticle,
  putArticle
} from "../../../../scripts/redux/actions/draftArticleActions";

const Publish = ({
  variant = "secondary",
  username,
  article,
  hasUnsavedChanges,
  saveBeforePublishing,
  text,
  history
}) => {
  const dispatch = useDispatch();

  const saveArticle = async article => {
    return new Promise((resolve, reject) => {
      dispatch(
        putArticle(
          { username, article, articleId: article.articleId },
          (article, error) => {
            if (error) {
              reject(error);
              window.alert(error.callStack || error);
            } else {
              resolve(article);
            }
          }
        )
      );
    });
  };

  const publishArticleClick = async article => {
    if (!username) {
      return;
    }

    const confirmationMessage =
      "Unsaved article detected! All unsaved changes will be lost. Are you sure you want to continue?";

    if (saveBeforePublishing) {
      await saveArticle(article);
    } else if (hasUnsavedChanges && !window.confirm(confirmationMessage)) {
      return;
    }

    dispatch(
      publishArticle({ username, article }, (_, error) => {
        if (!error) {
          history.push("/article/published");
        }
      })
    );
  };

  return (
    <Button variant={variant} onClick={() => publishArticleClick(article)}>
      <FontAwesomeIcon icon={faUpload} size="1x" />
      {text && <span className="pl-2">{text}</span>}
    </Button>
  );
};

export default withRouter(Publish);
