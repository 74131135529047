import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSkull } from "@fortawesome/free-solid-svg-icons";

export default ({ children }) => (
  <div className="container">
    <div className="row">
      <div className="col-12 mt-2">
        <h2 className="text-center display-4">
          <div>{children || "Unauthorised!"}</div>
          <FontAwesomeIcon icon={faSkull} size="1x" />
        </h2>
      </div>
    </div>
  </div>
);
