import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { getAdminUsers } from "../../../scripts/redux/actions/admin/adminUserActions";
import Table from "../../../components/common/_table";

const UserList = () => {
  const { users, loading } = useSelector(state => state.adminUserReducer);
  const dispatch = useDispatch();

  if (!users && !loading) {
    dispatch(getAdminUsers());
  }

  const UserRow = ({ data: user }) => {
    const { username } = user;
    return (
      <td>
        <Link className="btn btn-link" to={`/admin/user/${username}`}>
          {username}
        </Link>
      </td>
    );
  };

  return (
    <div className="text-left">
      <div className="jumbotron jumbotron-fluid bg-primary text-light">
        <div className="container">
          <h1 className="display-4">
            <span className="pr-2">
              <FontAwesomeIcon icon={faUser} size="1x" />
              <span className="pl-2">Manage Users</span>
            </span>
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <Table
            row={UserRow}
            data={users}
            loading={loading}
            noDataText="No users!"
          />
        </div>
      </div>
    </div>
  );
};

export default UserList;
