import React from "react";
import { useSelector } from "react-redux";
import Unauthorised from "./_unauthorised";

export default ({ children, showWarning = false }) => {
  const { loggedIn, user } = useSelector(state => state.authenticationReducer);
  if (!loggedIn || !user) {
    if (showWarning) {
      return <Unauthorised>You are not logged in!</Unauthorised>;
    }
    return <div />;
  }
  return <>{children}</>;
};
