import {
  SET_MODERATOR_REVIEW_LOADING,
  SET_MODERATOR_REVIEWS
} from "../../actions/moderator/moderatorReviewActions.js";

const initialState = {
  users: null,
  loading: false
};

const moderatorReviewReducer = (
  state = initialState,
  { type, data, loading } = {}
) => {
  switch (type) {
    case SET_MODERATOR_REVIEW_LOADING:
      return Object.assign({}, state, {
        loading
      });
    case SET_MODERATOR_REVIEWS:
      return Object.assign({}, state, {
        reviews: data,
        loading: false
      });
    default:
      return state;
  }
};

export default moderatorReviewReducer;
