import React, { useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  getAdminInquests,
  deleteAdminInquest
} from "../../../scripts/redux/actions/admin/inquestActions";
import Table from "../../../components/common/_table";

const InquestList = () => {
  const { inquests, loading } = useSelector(state => state.adminInquestReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!inquests && !loading) {
      dispatch(getAdminInquests());
    }
  }, [inquests, loading, dispatch]);

  const deleteAdminInquestClick = inquest => {
    if (!loading) {
      dispatch(deleteAdminInquest({ inquest }));
    }
  };

  const InquestRow = ({ data: inquest }) => (
    <>
      <td className="align-middle">{inquest.email}</td>
      <td className="text-right align-middle">
        {moment(inquest.createdDate).fromNow()}
      </td>
      <td className="text-right">
        <button
          className="btn btn-danger"
          onClick={() => deleteAdminInquestClick(inquest)}
          disabled={loading}
        >
          <FontAwesomeIcon icon={faTrashAlt} size="1x" />
        </button>
      </td>
    </>
  );

  return (
    <div className="text-left">
      <div className="jumbotron jumbotron-fluid bg-primary text-light">
        <div className="container">
          <h1 className="display-4">
            <span className="pr-2">
              <FontAwesomeIcon icon={faHandshake} size="1x" />
              <span className="pl-2">Inquests</span>
            </span>
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <Table
            row={InquestRow}
            data={inquests}
            loading={loading}
            noDataText="No inquests!"
          />
        </div>
      </div>
    </div>
  );
};

export default InquestList;
