import React from "react";

const ReadOnlyRow = ({ name, value, children }) => {
  const id = name.replace(" ", "-");
  return (
    <>
      <label
        htmlFor={`input-${id}`}
        id={`label-${id}`}
        className="col-sm-3 col-form-label bg-secondary text-nowrap"
      >
        {name}
      </label>
      <div className="col-sm-9">
        {value ? (
          <input
            className="form-control-plaintext text-light"
            id={`input-${id}`}
            aria-describedby={`label-${id}`}
            placeholder={name}
            value={value}
            readOnly
          />
        ) : (
          children
        )}
      </div>
    </>
  );
};

export default ReadOnlyRow;
