/* jshint -W014 */

import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import monitorReducersEnhancer from "./enhancers/monitorReducers";
import loggerMiddleware from "./middleware/logger";
import publishedArticleReducer from "./reducers/publishedArticleReducer";
import authenticationReducer from "./reducers/authenticationReducer";
import userApiKeyReducer from "./reducers/user/apiKeyReducer";
import moderatorReviewReducer from "./reducers/moderator/moderatorReviewReducer";
import adminUserReducer from "./reducers/admin/adminUserReducer";
import crudReducer from "./reducers/crudReducer";
import objectReducer from "./reducers/objectReducer";
import ObjectCrudReducer from "./reducers/objectCrudReducer";
import PaginatedCollectionReducer from "./reducers/paginatedCollectionReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["draftArticleReducer", "authenticationReducer"]
};

const initialiseReduxStore = preloadedState => {
  const { NODE_ENV } = process.env;

  const middlewares = [loggerMiddleware, thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = (NODE_ENV !== "production"
    ? composeWithDevTools
    : compose)(...enhancers);

  const rootReducer = combineReducers({
    articleReducer: crudReducer("articles", "articleId"),
    draftArticleReducer: ObjectCrudReducer("draftArticles", "articleId"),
    publishedArticles: PaginatedCollectionReducer("publishedArticle"),
    publishedArticleReducer,
    authenticationReducer,
    userReducer: crudReducer("users"),
    userInvitationReducer: crudReducer("invitations"),
    userApiKeyReducer,
    moderatorReviewReducer,
    adminUserReducer,
    adminInquestReducer: crudReducer("inquests", "key"),
    userAuthenticationReducer: objectReducer("userAuthentictaion", {
      success: false,
      loading: false
    })
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(
    persistedReducer,
    preloadedState,
    composedEnhancers
  );
  const persistor = persistStore(store);

  return { store, persistor };
};

export default initialiseReduxStore;
